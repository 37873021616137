<mat-accordion>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header
      collapsedHeight="3.125rem"
      expandedHeight="3.125rem"
    >
      <mat-panel-title>
        {{ title }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <br /><br />
    <ng-template matExpansionPanelContent>
      <ng-content></ng-content>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
