<h2 class="title"><br />Choisissez votre mot de passe</h2>
<div class="form-container">
  <form
    (ngSubmit)="
      this.authService.isForgotPassword ? forgotPassword() : resetpassword()
    "
    [formGroup]="resetPasswordform"
    class="reset-password-form"
  >
    <div class="header">
      <button
        (click)="handleBack()"
        class="menu-btn"
        type="button"
        mat-flat-button
      >
        <mat-icon>arrow_back</mat-icon>
        <span>Revenir en arrière</span>
      </button>
    </div>
    <ng-container *ngIf="this.authService.isForgotPassword">
      <div class="text-input-container input-container">
        <mat-label>Code de vérification reçu par mail </mat-label>
        <div class="input-button">
          <app-input
            id="confirmationCode"
            type="text"
            pattern="[0-9]*"
            placeholder="Entrez le code de vérification"
            [formCtrl]="resetPasswordform.get('confirmationCode')! | formControl"
            [errorMessages]="errorMessagesCode"
          >
          </app-input>
          <button
            *ngIf="this.authService.isForgotPassword"
            [disabled]="isLoadingSubmit"
            class="default-btn bordered"
            id="resetpassword-resend-confirmation-code"
            mat-flat-button
            type="button"
            (click)="sendConfirmationCode()"
          >
            <span *ngIf="!isLoadingSendConfirmationCode">Renvoyer le code</span>
            <i
              *ngIf="!isLoadingSendConfirmationCode"
              class="ph ph-paper-plane-right"
            ></i>
            <mat-spinner
              *ngIf="isLoadingSendConfirmationCode"
              [diameter]="30"
              class="default-loader"
            ></mat-spinner>
          </button>
        </div>
        <p class="warning-paragraph">
          <mat-icon>warning</mat-icon>
          <span class="warning">
            Merci de ne pas fermer cette page, vous devez saisir le code de vérification et
            ensuite valider pour effectuer la modification de votre email
          </span>
        </p>
        <mat-hint
          >Nous vous avons envoyé un mail contenant un code à 6 chiffres à
          l'adresse <strong>{{ this?.emailForgotPassword }}</strong
          >. Veuillez vérifier votre boîte mail et copier le code ci-dessus.
        </mat-hint>
      </div>
    </ng-container>

    <div>
      <div class="text-input-container">
        <mat-label>Entrez votre nouveau mot de passe</mat-label>
        <mat-form-field hideRequiredMarker appearance="outline">
          <input
            [type]="hiddenPassword ? 'text' : 'password'"
            formControlName="password"
            id="password"
            matInput
            placeholder="Votre nouveau mot de passe"
            (paste)="disablPasteRightClick($event)"
            (contextmenu)="disablPasteRightClick($event)"
          />
          <em
            (click)="hiddenPassword = !hiddenPassword"
            class="ph-{{ hiddenPassword ? 'eye' : 'eye-closed' }}"
            matSuffix
          ></em>
        </mat-form-field>
        <mat-error *ngIf="f.password.errors && f.password.dirty; else elseBlock"
          >Le mot de passe doit contenir 12 caractères, avec au moins une
          minuscule, une majuscule, un chiffre et un caractère spécial.
        </mat-error>
        <ng-template #elseBlock>
          <mat-hint
            >Le mot de passe doit contenir 12 caractères, avec au moins une
            minuscule, une majuscule, un chiffre et un caractère spécial.
          </mat-hint>
        </ng-template>
      </div>
    </div>

    <div class="text-input-container">
      <mat-label>Confirmez votre nouveau mot de passe</mat-label>
      <mat-form-field hideRequiredMarker appearance="outline">
        <input
          [type]="hiddenConfirmPassword ? 'text' : 'password'"
          formControlName="password_confirm"
          id="password-confirm"
          matInput
          placeholder="Votre nouveau mot de passe"
          (paste)="disablPasteRightClick($event)"
          (contextmenu)="disablPasteRightClick($event)"
        />

        <em
          (click)="hiddenConfirmPassword = !hiddenConfirmPassword"
          class="ph-{{ hiddenConfirmPassword ? 'eye' : 'eye-closed' }}"
          matSuffix
        ></em>
      </mat-form-field>
      <mat-error
          *ngIf="f.password_confirm.errors?.mustMatch"
          id="passwordErrorRequired"
          >Les deux mots de passe ne sont pas identiques
        </mat-error>
    </div>

    <div class="submit-form">
      <button
        (click)="cancel()"
        [disabled]="isLoadingSubmit"
        class="default-btn"
        id="resetpassword-cancel"
        mat-raised-button
      >
        <span>Annuler</span>
        <i class="ph ph-x-circle"></i>
      </button>
      <button
        [disabled]="
          f.password.invalid ||
          f.password_confirm.invalid ||
          isLoadingSubmit ||
          (this.authService.isForgotPassword && f.confirmationCode.invalid)
        "
        class="default-btn"
        id="resetpassword-validate"
        mat-flat-button
        type="submit"
      >
        <span *ngIf="!isLoadingSubmit">Me connecter</span>
        <i *ngIf="!isLoadingSubmit" class="ph ph-sign-in"></i>
        <mat-spinner
          *ngIf="isLoadingSubmit"
          [diameter]="30"
          class="default-loader"
          id="mat-spinner"
        ></mat-spinner>
      </button>
    </div>
  </form>
</div>
