import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { User } from '@app/shared/models/user-info';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import { appendParams } from '@app/shared/utils/services-utils';
import { environment } from '@environments/environment';
import { Subject, map, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService implements OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  userConnected?: User;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  public getPCT(params: { limit?: number; partnerId: string | null }) {
    const url = `${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CONTRACTS}`;
    return this.httpClient
      .get(url, {
        params: appendParams(params),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res: unknown) => res));
  }

  public getOwnerPartners(params: { partnerId: string | null }) {
    const url = `${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.OWNER_PARTNERS}`;
    return this.httpClient
      .get(url, {
        params: appendParams(params),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res: any) => res));
  }

  public changeUserInfo(mobilePhone: string, mobileCountry: string, fixedPhone: string, fixedCountry: string) {
    const url = `${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CONTACTS}/${this.authService.getProfileID()}`;
    return this.httpClient
      .post(url, {
        version: this.userConnected?.selectedProfile?.version,
        countryCodeFixe: fixedCountry !== 'international' ? fixedCountry.toUpperCase() : undefined,
        fixedPhone1: fixedPhone,
        countryCodeMobile: mobileCountry !== 'international' ? mobileCountry.toUpperCase() : undefined,
        mobilePhonePerso: mobilePhone,
        id: this.userConnected?.selectedProfile?.id,
      })
      .pipe(map((res: unknown) => res));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
