import { Component, Input } from '@angular/core';
import { Color } from '@app/shared/models/color';

export enum Size {
  'small' = '16px',
  'mediumSmall' = '20px',
  'medium' = '24px',
  'large' = '32px',
}

export enum Style {
  'bold' = 'ph-bold',
  'fill' = 'ph-fill',
  'light' = 'ph-light',
}

export enum Align {
  'left' = 'left',
  'right' = 'right',
  'center' = 'center',
}

const defaultSize = Size.medium;

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
})
export class IconComponent {
  @Input() phosphorClass: string = 'ph-question';

  @Input() iconStyle?: Style | undefined;

  @Input() size?: Size = defaultSize;

  @Input() color: Color | undefined = Color.greyBlack;

  @Input() align: Align = Align.center;

  defaultSize: Size = defaultSize;

  get class(): string {
    if (this.iconStyle) {
      return `ph ${this.phosphorClass} ${this.iconStyle}`;
    }
    return `ph ${this.phosphorClass}`;
  }
}
