import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToggleSwitchSize } from '@app/shared/models/toggle-switch.model';

@Component({
  selector: 'app-toggle-switch-check',
  templateUrl: './toggle-switch-check.component.html',
  styleUrls: ['./toggle-switch-check.component.scss'],
})
export class ToggleSwitchCheckComponent {
  @Input() size: ToggleSwitchSize = ToggleSwitchSize.medium;

  @Input() disabled: boolean = false;

  @Input() options: Array<{ key: string; value: string }> = [];

  @Input() selected?: string;

  @Output() selectedChange = new EventEmitter<string>();

  onToggle(value: string) {
    if (this.selected !== value) {
      this.selected = value;
      this.selectedChange.emit(value);
    }
  }

  isSelected(value: string): boolean {
    return this.selected === value;
  }
}
