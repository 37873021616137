<div class="toggle-switch-check" [ngClass]="size">
  <ng-container *ngFor="let option of options">
    <button
      class="toggle-option"
      [class.selected]="isSelected(option.value)"
      [disabled]="disabled"
      (click)="onToggle(option.value)">
      {{ option.key }}
    </button>
  </ng-container>
</div>
