import { Component, Input } from '@angular/core';
import { Category } from '@app/shared/enums/category';

@Component({
  selector: 'app-price-production',
  templateUrl: './priceProduction.component.html',
  styleUrls: ['./priceProduction.component.scss'],
})
export class PriceProductionComponent {
  @Input() production: number;

  @Input() priceMajUnder: number;

  @Input() category: Category;

  @Input() numeroDossier!: string | undefined;
}
