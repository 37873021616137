<div class="invoices-container">
  <div class="invoices-header">
    <div class="title-container">
      <h1 class="invoices-title">Toutes mes factures</h1>
    </div>
    <div class="actions-header">
      <button
        class="default-btn"
        type="button"
        mat-flat-button
        (click)="showInfoCheckingStatusInvoice()"
        id="infoInvoiceCheckingBtn"
      >
        <i class="square ph-info"></i>
        <span> En savoir plus : Facture en cours de vérification</span>
      </button>
      <button
        class="default-btn"
        type="button"
        mat-flat-button
        (click)="showInfo()"
        id="infoBtn"
      >
        <i class="square ph-info"></i>
        <span>Informations délais de règlements</span>
      </button>
    </div>
  </div>
  <div>
    <div class="text-input-container">
      <mat-form-field
        hideRequiredMarker
        appearance="outline"
        class="search-field"
      >
        <input
          type="text"
          matInput
          [formControl]="searchInvoices"
          (keydown.enter)="getInvoices()"
          placeholder="Facture, Dossier, Contrat"
        />

        <em (click)="getInvoices()" class="ph-magnifying-glass" matSuffix></em>
      </mat-form-field>
    </div>

    <app-table
      [dataSource]="dataSource"
      [columns]="dataColumns"
      [sortActive]="'invoiceDate'"
      [sortDirection]="'asc'"
      [isLoading]="isLoading"
      [downloading]="downloading"
      [noDataMessage]="noDataMessage"
      [tableId]="'invoices-table'"
      (sortChange)="announceSortChange($event)"
      (actionEvent)="handleActionEvent($event)"
    ></app-table>
    <app-paginator></app-paginator>
  </div>


</div>
