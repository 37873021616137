import parsePhoneNumber from 'libphonenumber-js';

export class PhoneNumberService {
  static formatPhoneNumber(rawPhoneNumber: string | undefined): string | undefined {
    if (typeof rawPhoneNumber === 'string') {
      const phoneNumber = parsePhoneNumber(rawPhoneNumber, 'FR');
      if (phoneNumber && phoneNumber.isValid() && rawPhoneNumber.charAt(0) === '0') {
        return phoneNumber?.formatNational();
      }
    }
    return rawPhoneNumber;
  }

  static getPhoneNumberCountry(rawPhoneNumber: string | undefined): string {
    if (typeof rawPhoneNumber === 'string') {
      const phoneNumber = parsePhoneNumber(rawPhoneNumber, 'FR');
      if (phoneNumber && phoneNumber.isValid() && rawPhoneNumber.charAt(0) === '0') {
        return 'fr';
      }
    }
    return 'international';
  }
}
