import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
})
export class DatepickerComponent implements OnInit, OnChanges {
  @Input() className: string = '';

  @Input() dateLabel: string = '';

  @Input() minDate: DateTime | null = null;

  @Input() maxDate: DateTime | null = null;

  @Input() matDatepickerMinLabel: string = 'Date minimum non respectée';

  @Input() matDatepickerMaxLabel: string = 'Date maximum non respectée';

  @Input() hint: string = '';

  @Input() disabled: boolean = false;

  @Input() required: boolean = false;

  @Input() value: DateTime;

  @Input() date: FormControl<DateTime | null>;

  @Output() dateValue = new EventEmitter<DateTime | null>();

  @Output() hasError = new EventEmitter<boolean>();

  reset(): void {
    this.date.setValue(null);
  }

  ngOnInit(): void {
    this.date.valueChanges.subscribe((value) => {
      this.dateValue.emit(value);
      this.hasError.emit(this.date.invalid);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled?.currentValue) {
      this.date.disable();
    } else {
      this.date.enable();
    }

    this.hasError.emit(this.date.invalid);
  }
}
