<div class="contracts-container">
  <div class="contracts-header">
    <div class="title-container">
      <h1 class="contracts-title">Tous mes contrats</h1>
      <app-toggle-switch-check
        [size]="ToggleSwitchSize.large"
        [disabled]="isLoading"
        [options]="contractOptions"
        [selected]="currentStatus"
        (selectedChange)="onClickContractToggleButton($event)">
      </app-toggle-switch-check>
    </div>
  </div>
  <div class="text-input-container">
    <mat-form-field
      hideRequiredMarker
      appearance="outline"
      class="search-field"
    >
      <input
        type="text"
        matInput
        [formControl]="searchContracts"
        (keydown.enter)="getContracts()"
        placeholder="Dossier, Contrat, Titulaire"
      />

      <em (click)="getContracts()" class="ph-magnifying-glass" matSuffix></em>
    </mat-form-field>
  </div>
  <app-table
    [dataSource]="dataSource"
    [columns]="dataColumns"
    [routerCondition]="this.isActualContract"
    [routerLink]="'/home/contracts-history/'"
    [isLoading]="isLoading"
    [noDataMessage]="noDataMessage"
    [tableId]="'contracts-table'"
    (sortChange)="announceSortChange($event)"
  ></app-table>
  <app-paginator></app-paginator>
</div>
