import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
})
export class InputComponent implements OnInit {
  @Input() id: string;

  @Input() errorMessages: { name: string; value: string }[];

  @Input() placeholder: string;

  @Input() readonly: boolean;

  @Input() type: string = 'string';

  @Input() formCtrl: FormControl = new FormControl();

  @Output() inputValue = new EventEmitter<string | null>();

  @Output() hasError = new EventEmitter<ValidationErrors | null>();

  ngOnInit(): void {
    this.formCtrl.updateValueAndValidity();
    if (!this.errorMessages) this.errorMessages = this.getDefaultErrorMessage();

    this.formCtrl.valueChanges.subscribe(() => {
      this.formCtrl.markAsTouched();
      this.emitInputError();
      this.emitInputValue();
    });

    this.emitInputError();
    this.emitInputValue();
  }

  getDefaultErrorMessage() {
    switch (this.type) {
      case 'email':
        return [
          { name: 'required', value: 'Le champ email est requis' },
          { name: 'pattern', value: "Le format de l'email est incorrect" },
        ];
      default:
        return [
          { name: 'required', value: 'Le champ est requis' },
          { name: 'pattern', value: "Le champ n'est pas au bon format" },
        ];
    }
  }

  updateInput($event: Event) {
    const input = $event.target as HTMLInputElement;
    this.formCtrl.setValue(String(input.value));
    this.formCtrl.markAsTouched();
    this.emitInputError();
    this.emitInputValue();
  }

  keyupInput($event: Event) {
    const input = $event.target as HTMLInputElement;
    this.formCtrl.setValue(String(input.value));
    this.formCtrl.markAsUntouched();
    this.emitInputError();
    this.emitInputValue();
  }

  emitInputError() {
    this.hasError.emit(this.formCtrl.errors);
  }

  emitInputValue() {
    this.inputValue.emit(this.formCtrl.value);
  }
}
