<div class="document-list">
  <a
    id="{{id}}"
    target="_blank"
    href="{{link}}"
  >
    <mat-card [class.mobile]="(isHandset$ |async)">
      <div class="document-card">
        <em class="icon ph-file-pdf"></em>
        <span>{{title}}</span>
      </div>
      <div class="bottom"></div>
    </mat-card>
  </a>
</div>
