<div class="change-password-container">
  <div class="form-container">
    <form
      [formGroup]="changePasswordform"
      class="change-password-form"
      (ngSubmit)="submitForm()"
    >
      <div class="form-sections">
        <div class="text-input-container oldPassword-container">
          <mat-label>Ancien mot de passe</mat-label>
          <app-password-input
            id="oldPassword"
            [formCtrl]="
              this.changePasswordform.get('oldPassword')! | formControl
            "
            [onChange]="this.onChange"
            placeholder="Entrez votre mot de passe actuel"
          >
          </app-password-input>
          <mat-error
            *ngIf="controls.oldPassword?.errors?.incorrectOldPassword"
            id="oldPasswordError"
          >
            {{ INFORMATION.CHANGE_PASSWORD_INCORRECT_OLD_PASSWORD }}
          </mat-error>
        </div>

        <div class="text-input-container password-container">
          <mat-label>Nouveau mot de passe</mat-label>
          <app-password-input
            id="newPassword"
            [formCtrl]="
              this.changePasswordform.get('newPassword')! | formControl
            "
            [onChange]="this.onChange"
            placeholder="Entrez votre nouveau mot de passe"
          >
          </app-password-input>
          <mat-error
            *ngIf="controls.newPassword?.errors?.cantMatch"
            id="cantMatchError"
          >
            {{ INFORMATION.CHANGE_PASSWORD_CANT_MATCH }}
          </mat-error>
        </div>

        <div class="text-input-container password-confirm-container">
          <mat-label>Confirmez votre nouveau mot de passe</mat-label>
          <app-password-input
            id="confirmPassword"
            [formCtrl]="
              this.changePasswordform.get('confirmPassword')! | formControl
            "
            [onChange]="this.onChange"
            placeholder="Entrez votre nouveau mot de passe"
          >
          </app-password-input>
          <mat-error
            *ngIf="controls.confirmPassword?.errors?.mustMatch"
            id="shouldMatchError"
          >
            {{ INFORMATION.CHANGE_PASSWORD_SHOULD_MATCH }}
          </mat-error>
          <mat-hint
            [ngStyle]="{
              color:
                passwordErrors && controls.newPassword.dirty ? 'red' : '#585d66'
            }"
            id="password-format"
          >
            {{ INFORMATION.CHANGE_PASSWORD_REQUIREMENTS }}
          </mat-hint>
        </div>

        <div
          *ngIf="controls.newPassword?.errors?.incorrectPassword"
          class="submit-error"
          id="submittedError"
        >
          {{ MESSAGES.INFO_ERROR }}
        </div>
      </div>
    </form>
  </div>
</div>
