import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import { environment } from '@environments/environment';
import { AuthService } from '@app/shared/auth/auth.service';
import { User } from '@app/shared/models/user-info';

interface EmailData {
  contact: string;
  contract: string;
  email: string;
  phone: string;
  theme: string;
  comment: string;
  files: {
    content: string;
    name: string;
    type: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ContactService implements OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  userConnected?: User;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  /**
   * @param data données du formulaire
   */
  public sendEmail(data: EmailData): Observable<unknown> {
    return this.http
      .post<{ data: unknown }>(`${environment.PA_API_BASE_URL_CONTACT_PROVIDER}${API_ROUTES.SEND_EMAIL}`, data)
      .pipe(map((response) => response.data));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
