<div class="side-container" [class.mobile]="(isHandset$ |async)">
  <mat-card class="bloc-container">
    <p>Mes Guides</p>
    <app-download-file
      id="doc-relever-index"
      link="https://static.soregies.fr/divers/COMMENT+RELEVER+SES+INDEX.pdf"
      title="Comment relever ses index"
    >
    </app-download-file>
    <app-download-file
      id="doc-mode-operatoire"
      link="https://static.soregies.fr/divers/Mode+Op%C3%A9ratoire+Espace+Producteur+.pdf"
      title="Mode opératoire Espace Producteur"
    >
    </app-download-file>
  </mat-card>
</div>
