import { Component, Input } from '@angular/core';
import { MESSAGES } from '@app/shared/utils/messages';
import { ErrorType } from './error.model';

@Component({
  selector: 'app-error-section',
  templateUrl: './error-section.component.html',
  styleUrls: ['./error-section.component.scss'],
})
export class ErrorSectionComponent {
  messages = MESSAGES;

  errorType = ErrorType;

  @Input() error: ErrorType = ErrorType.Error;
}
