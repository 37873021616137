import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { AuthService } from '@app/shared/auth/auth.service';
import { MESSAGES } from '@app/shared/utils/messages';
import { Subject, takeUntil } from 'rxjs';
import { UserInfoService } from '../user-info.service';

@Component({
  selector: 'app-change-user-info',
  templateUrl: './change-user-info.component.html',
  styleUrls: ['./change-user-info.component.scss'],
})
export class ChangeUserInfoComponent implements OnDestroy {
  @Input() setEnable: (args: boolean) => void;

  @Input() setLoading: (args: boolean) => void;

  @Input() data: any;

  protected isLoadingSubmit: boolean = false;

  // userConnected?: User;

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  protected MESSAGES = MESSAGES;

  protected MOBILE_PHONE_RE = '^0[6 | 7]([\\s][0-9]{2}){4}$';

  protected FIXED_PHONE_RE = '^0[1-5 | 9]([\\s][0-9]{2}){4}$';

  mobilePhoneError: ValidationErrors | null;

  fixedPhoneError: ValidationErrors | null;

  mobilePhoneValue: string | null = '';

  mobilePhoneCountry: string | null = 'fr';

  fixedPhoneValue: string | null = '';

  fixedPhoneCountry: string | null = 'fr';

  changeUserInfoForm = new UntypedFormGroup({});

  constructor(
    public authService: AuthService,
    public userInfoService: UserInfoService,
  ) {}

  submitForm(): Promise<boolean | void> {
    this.isLoadingSubmit = true;
    return new Promise<boolean | void>((resolve, reject) => {
      this.userInfoService
        .changeUserInfo(
          this.mobilePhoneValue ?? '',
          this.mobilePhoneCountry ?? '',
          this.fixedPhoneValue ?? '',
          this.fixedPhoneCountry ?? '',
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            resolve(true);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  mobileError(event: ValidationErrors | null) {
    this.mobilePhoneError = event;
    this.onChange();
  }

  fixedError(event: ValidationErrors | null) {
    this.fixedPhoneError = event;
    this.onChange();
  }

  setMobilePhoneValue(event: string | null) {
    this.mobilePhoneValue = event;
    this.onChange();
  }

  setMobilePhoneCountry(event: string | null) {
    this.mobilePhoneCountry = event;
    this.onChange();
  }

  setFixedPhoneValue(event: string | null) {
    this.fixedPhoneValue = event;
    this.onChange();
  }

  setFixedPhoneCountry(event: string | null) {
    this.fixedPhoneCountry = event;
    this.onChange();
  }

  isRequired() {
    return (
      (this.fixedPhoneValue ? /^\\s.*$/.test(this.fixedPhoneValue) : true) &&
      (this.mobilePhoneValue ? /^\\s.*$/.test(this.mobilePhoneValue) : true)
    );
  }

  onChange = () => {
    const disable =
      this.mobilePhoneError?.pattern ||
      this.fixedPhoneError?.pattern ||
      this.isRequired() ||
      (this.mobilePhoneValue === this.data.contact.mobilePhone &&
        this.fixedPhoneValue === this.data.contact.fixedPhone);
    this.setEnable(!disable);
  };

  get controls() {
    return this.changeUserInfoForm.controls;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
