import { HttpParams } from '@angular/common/http';

export function appendParams(object: Record<string, any>): HttpParams {
  let params: HttpParams = new HttpParams();
  Object.keys(object).forEach((key) => {
    if (object[key] === undefined || object[key] === null) return;
    if (typeof object[key] === 'string' && !object[key].length) return;
    if (object[key] instanceof Array) {
      Object.keys(object[key]).forEach((subkey) => {
        params = params.append(key, subkey);
      });
    } else params = params.append(key, object[key]);
  });
  return params;
}
