import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-menu-navigation',
  templateUrl: './menu-navigation.component.html',
  styleUrls: ['./menu-navigation.component.scss'],
})
export class MenuNavigationComponent {
  @Input() isMenuOpen = true;
}
