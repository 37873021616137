import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Alert, AlertType } from './alert.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private subject: BehaviorSubject<Alert | null> = new BehaviorSubject<Alert | null>(null);

  getMessage(): Observable<Alert | null> {
    return this.subject.asObservable();
  }

  success(
    message: string,
    options?: {
      icon?: string;
      duration?: number;
      horizontalPosition?: string;
      verticalPosition?: string;
      keepAfterNavigationChange?: string;
    },
  ) {
    this.alert({
      ...options,
      type: AlertType.Success,
      message,
    });
  }

  error(
    message: string,
    options?: {
      icon?: string;
      duration?: number;
      horizontalPosition?: string;
      verticalPosition?: string;
      keepAfterNavigationChange?: string;
    },
  ) {
    this.alert({
      ...options,
      type: AlertType.Error,
      message,
    });
  }

  info(
    message: string,
    options?: {
      icon?: string;
      duration?: number;
      horizontalPosition?: string;
      verticalPosition?: string;
      keepAfterNavigationChange?: string;
    },
  ) {
    this.alert({
      ...options,
      type: AlertType.Info,
      message,
    });
  }

  warn(
    message: string,
    options?: {
      icon?: string;
      duration?: number;
      horizontalPosition?: string;
      verticalPosition?: string;
      keepAfterNavigationChange?: string;
    },
  ) {
    this.alert({
      ...options,
      type: AlertType.Warning,
      message,
    });
  }

  alert(data: {}) {
    const alert = new Alert(data);
    this.subject.next(alert);
  }

  clear() {
    this.subject.next(null);
  }
}
