import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Event as NavigationEvent, NavigationStart, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AlertType } from './alert.model';
import { AlertService } from './alert.service';
import { SnackbarMessageTemplateComponent } from './snackbarMessageTemplate.component';

@Component({
  selector: 'app-alert',
  template: '',
  styles: [],
})
export class AlertComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  message: string;

  private keepAfterNavigationChange = false;

  constructor(
    private alertService: AlertService,
    private router: Router,
    public snackBar: MatSnackBar,
  ) {}

  static getSnackBarType(type: AlertType): string {
    const alertTypeClass = {
      [AlertType.Success]: 'snackbar-success',
      [AlertType.Error]: 'snackbar-danger',
      [AlertType.Info]: 'snackbar-info',
      [AlertType.Warning]: 'snackbar-warning',
    };

    return alertTypeClass[type];
  }

  ngOnInit() {
    this.alertMessage();
    this.routerEvent();
  }

  alertMessage() {
    this.alertService
      .getMessage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((alert) => {
        this.keepAfterNavigationChange = alert?.keepAfterNavigationChange ?? false;

        if (alert?.message != null) {
          const config = new MatSnackBarConfig();
          config.duration = alert.duration;
          config.panelClass = [AlertComponent.getSnackBarType(alert.type)];
          config.verticalPosition = alert.verticalPosition as MatSnackBarVerticalPosition;
          config.horizontalPosition = alert.horizontalPosition as MatSnackBarHorizontalPosition;
          config.data = {
            message: alert.message,
            prefixIcon: alert.icon,
          };
          this.snackBar.openFromComponent(SnackbarMessageTemplateComponent, config);
        } else {
          this.snackBar.dismiss();
        }
      });
  }

  routerEvent() {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          this.keepAfterNavigationChange = false;
        } else {
          this.snackBar.dismiss();
          this.alertService.clear();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
