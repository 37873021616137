import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvoicesService } from '@app/components/invoice/invoices.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { ConfirmDialogData } from '@app/shared/components/dialog/dialog.data';
import { DialogService } from '@app/shared/components/dialog/dialog.services';
import { PaginatorService } from '@app/shared/components/paginator/paginator.service';
import { InvoiceDataResponse, InvoiceResponse } from '@app/shared/interfaces/invoice.interface';
import { MESSAGES } from '@app/shared/utils/messages';
import { Subject, takeUntil } from 'rxjs';
import { DataColumns } from '@app/shared/interfaces/table.interface';
import { getPaymentDate } from './helper';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnDestroy, OnInit {
  noDataMessage = 'Aucune donnée présente';

  @Input() searchInvoices: FormControl = new FormControl();

  dataSource: MatTableDataSource<InvoiceDataResponse> = new MatTableDataSource<InvoiceDataResponse>();

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  resultLimit: number = 10;

  resultOffset: number = 0;

  totalInvoiceNumber: number = 0;

  isLoading: boolean = true;

  downloading: boolean[] = [];

  dataColumns: DataColumns[] = [
    {
      def: 'contractLine.contractLineId',
      header: 'Dossier',
      id: 'contract',
      class: 'default-cell default-column-type-semibold-center',
    },
    {
      def: 'invoiceDate',
      header: 'Date',
      type: 'date',
      id: 'date',
      class: 'default-cell default-column-type-regular-center',
    },
    {
      def: 'contractLine.mpt.code',
      header: 'Contrat',
      id: 'pct',
      class: 'default-cell default-column-type-semibold-center',
    },
    {
      def: 'meIntegrationFromDate',
      header: 'Date de début',
      type: 'date',
      id: 'startDate',
      class: 'default-cell default-column-type-regular-center',
    },
    {
      def: 'meIntegrationToDate',
      header: 'Date de fin',
      type: 'date',
      id: 'endDate',
      class: 'default-cell default-column-type-regular-center',
    },
    {
      def: 'inTaxTotal',
      header: 'Total TTC',
      type: 'number',
      id: 'total',
      class: 'default-cell default-column-type-regular-center',
      unit: '€',
    },
    { def: 'invoiceId', header: 'Facture', id: 'invoice', class: 'default-cell default-column-type-semibold-center' },
    {
      def: 'status.name',
      header: 'Statut',
      type: 'transform',
      id: 'status',
      class: 'default-cell default-column-type-regular-center',
      transform: this.transformStatus,
    },
    {
      def: 'paymentDate',
      header: 'Date de règlement',
      type: 'transform',
      id: 'paymentDate',
      class: 'default-cell default-column-type-semibold-center',
      transform: this.transformPaymentDate,
    },
    {
      def: 'download',
      header: 'Télécharger',
      id: 'download',
      class: 'default-cell default-column-type-semibold-center action-buttons',
      action: true,
      icon: 'ph-download-simple-bold',
    },
  ];

  constructor(
    private dialogService: DialogService,
    private authentication: AuthService,
    private invoicesService: InvoicesService,
    private paginatorService: PaginatorService,
    private alertService: AlertService,
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.initPaginator();

    this.initInvoices();
  }

  initInvoices() {
    this.authentication.authLoader$.pipe(takeUntil(this.unsubscribe$)).subscribe((authLoader) => {
      if (!authLoader) this.getInvoices();
    });
  }

  initPaginator() {
    this.paginatorService.limit.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (limit) => {
        this.resultLimit = limit;
      },
    });

    this.paginatorService.page.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (page) => {
        this.resultOffset = (page - 1) * this.resultLimit;
        this.getInvoices();
      },
    });
  }

  ngOnDestroy(): void {
    this.paginatorService.clear();
    this.dataSource.data = [];
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  showInfoCheckingStatusInvoice() {
    const options: ConfirmDialogData = {
      message: MESSAGES.INFO_CHECKING_STATUS_INVOICE,
      title: 'Votre facture est en cours de vérification.',
      confirmText: 'Fermer',
      enableConfirmButton: true,
      confirmFunction: () => {
        this.dialogService.close();
      },
    };
    this.dialogService.open(options);
  }

  showInfo() {
    const options: ConfirmDialogData = {
      message: MESSAGES.INFO_INVOICE,
      title: 'Informations délais de règlements',
      confirmText: 'Fermer',
      enableConfirmButton: true,
      confirmFunction: () => {
        this.dialogService.close();
      },
    };
    this.dialogService.open(options);
  }

  getInvoices(sortBy?: string): void {
    this.isLoading = true;

    this.invoicesService
      .getInvoices({
        limit: this.resultLimit,
        offset: this.resultOffset,
        sortBy: sortBy ?? '-invoiceDate',
        search: this.searchInvoices.value,
        partnerId: this.authentication.getProfileID(),
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: InvoiceResponse) => {
          this.paginatorService.itemProperties$.next({
            total: response.total,
            shown: response.data ? response.data.length : 0,
          });
          this.totalInvoiceNumber = response.total;
          if (response.data) this.dataSource.data = response.data;
          else {
            this.dataSource.data = [];
            this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          }
          this.isLoading = false;
        },
        error: () => {
          this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          this.alertService.error(MESSAGES.DEFAULT_ERROR);
          this.isLoading = false;
        },
      });
  }

  announceSortChange(sortState: Sort): void {
    const sortDirection = sortState.direction === 'asc' ? `-${sortState.active}` : sortState.active;
    this.getInvoices(sortDirection || undefined);
  }

  handleActionEvent(event: { element: InvoiceDataResponse; index: number }) {
    this.downloadInvoicePdf(event.element, event.index);
  }

  downloadInvoicePdf(element: InvoiceDataResponse, index: number) {
    this.downloading[index] = true;
    if (element['status.code'] === 'DFT') {
      this.alertService.info(MESSAGES.DOWNLOAD_INVOICE_FORBIDDEN);
      this.downloading[index] = false;
    } else {
      this.invoicesService
        .getInvoicePDF(element.id.toString())
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (pdfData) => {
            if (pdfData) {
              const a = document.createElement('a');
              a.href = `data:application/pdf;base64,${pdfData}`;
              a.download = `${element.id}.pdf`;
              a.click();
              this.downloading[index] = false;
              this.alertService.success(MESSAGES.DOWNLOAD_INVOICE_SUCCESS, {
                icon: 'currency-eur',
              });
            }
          },
          error: () => {
            this.alertService.error(MESSAGES.DOWNLOAD_INVOICE_ERROR, {
              icon: 'currency-eur',
            });
            this.downloading[index] = false;
          },
        });
    }
  }

  transformStatus(value: InvoiceDataResponse): string {
    const statusCode: string = value?.['status.code'];
    const statusName: string = value?.['status.name'];
    switch (statusCode) {
      case 'VLD CSPE':
        return 'Validée';
      case 'DFT':
        return 'En cours de validation';
      default:
        return statusName || 'Inconnu';
    }
  }

  transformPaymentDate(element: InvoiceDataResponse): string {
    return getPaymentDate(element?.['status.code'], element?.validateDate);
  }
}
