import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { COGNITO_MESSAGES } from '@app/shared/utils/cognito-messages';
import { INFORMATION } from '@app/shared/utils/information-constants';
import { MESSAGES } from '@app/shared/utils/messages';
import { CustomMatchingValidators } from '@app/shared/validators/matching.validator';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnDestroy {
  @Input() setEnable: (args: boolean) => void;

  @Input() setLoading: (args: boolean) => void;

  changePasswordform = new UntypedFormGroup(
    {
      oldPassword: new UntypedFormControl('', Validators.required),
      newPassword: new UntypedFormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(12),
          CustomMatchingValidators.patternValidator(/\d/, { hasNumber: true }),
          CustomMatchingValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          CustomMatchingValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          CustomMatchingValidators.patternValidator(/^(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S.*$/, {
            hasSpecialCharacters: true,
          }),
        ],
      }),
      confirmPassword: new UntypedFormControl('', Validators.required),
    },
    {
      validators: [CustomMatchingValidators.mustMatch, CustomMatchingValidators.cantMatchOldPassword],
    },
  );

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  protected MESSAGES = MESSAGES;

  protected INFORMATION = INFORMATION;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private alertService: AlertService,
  ) {}

  get controls() {
    return this.changePasswordform.controls;
  }

  get passwordErrors() {
    const controlErrors = this.controls.newPassword?.errors;

    if (controlErrors && (controlErrors.size > 1 || (!controlErrors?.cantMatch && !controlErrors?.incorrectPassword))) {
      return true;
    }
    return false;
  }

  onChange = () => {
    const disable =
      this.controls.oldPassword.invalid ||
      (this.controls.newPassword.invalid && !this.controls.newPassword.hasError('incorrectPassword')) ||
      (this.controls.confirmPassword.invalid && !this.controls.newPassword.hasError('incorrectPassword'));
    this.setEnable(!disable);
  };

  submitForm(): Promise<boolean | void> {
    return new Promise<boolean | void>((resolve, reject) => {
      this.setLoading(true);
      if (this.controls.newPassword?.hasError('incorrectPassword')) {
        delete this.controls.newPassword?.errors?.cantMatch;
      }
      if (this.controls.oldPassword?.hasError('incorrectOldPassword')) {
        delete this.controls.oldPassword?.errors?.cantMatch;
      }

      this.authService
        .changePassword(
          this.changePasswordform.get('oldPassword')?.value,
          this.changePasswordform.get('newPassword')?.value,
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            this.alertService.success('Mot de passe changé avec succès');
            this.setLoading(false);
            resolve(true);
          },
          error: (err) => {
            this.setLoading(false);
            if (err.error?.message === COGNITO_MESSAGES.CGNT_INCORRECT_USERNAME_PASSWORD) {
              this.controls.oldPassword?.setErrors({ incorrectOldPassword: true });
            } else {
              this.controls.newPassword?.setErrors({ incorrectPassword: true });
            }
            reject(err);
          },
        });
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
