import { Color } from './color';

export interface ToggleSwitchInput {
  value: string;
  key: string;
  icon: string;
  colors?: ToggleSwitchInputColor;
}

export interface ToggleSwitchInputColor {
  selected?: Color;
  hover?: Color;
  text?: {
    selected?: Color;
    default?: Color;
  };
}

export enum ToggleSwitchSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}
