<div class="container white-background-color">
  <mat-form-field>
    <input
      id="{{ className }}"
      [value]="value"
      matInput
      class="{{ className }}"
      [matDatepicker]="datePicker"
      [formControl]="date"
      [min]="minDate"
      [max]="maxDate"
      required="required"
    />
    <mat-datepicker-toggle
      id="datepicker-{{ className }}"
      class="{{ className }}"
      matSuffix
      [for]="datePicker"
    >
      <em matDatepickerToggleIcon class="ph-calendar" matSuffix></em>
    </mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
  </mat-form-field>
  <mat-error *ngIf="date.hasError('required')">La date est requise</mat-error>
  <mat-error *ngIf="date.hasError('matStartDateInvalid')"
    >{{ dateLabel }} invalide</mat-error
  >
  <mat-error
    id="mat-error-matDatepickerMin"
    *ngIf="date.hasError('matDatepickerMin')"
    >{{ matDatepickerMinLabel }}</mat-error
  >
  <mat-error
    id="mat-error-matDatepickerMax"
    *ngIf="date.hasError('matDatepickerMax')"
    >{{ matDatepickerMaxLabel }}</mat-error
  >
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</div>
