import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  template: `
    <em class="ph-{{ data.prefixIcon }}-bold"></em>
    <span class="alert-message" [innerHTML]="data.message"></span>
    <em class="ph-x-circle closed-icon" (click)="snackBarRef.dismiss()"></em>
  `,
})
export class SnackbarMessageTemplateComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarMessageTemplateComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: { message: string; prefixIcon: string },
  ) {}
}
