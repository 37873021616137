<ul class="menu-navigation">
  <li
    [class.menu-close]="!isMenuOpen"
    [class.menu-open]="isMenuOpen"
    [routerLinkActiveOptions]="{ exact: true }"
    class="menu-button"
    routerLink="/home"
    routerLinkActive="active-list-item"
  >
    <div class="icon-container">
      <em class="ph-house" matTooltip="Accueil" matTooltipPosition="right"></em>
    </div>
    <span>Accueil</span>
  </li>

  <li
    [class.menu-close]="!isMenuOpen"
    [class.menu-open]="isMenuOpen"
    class="menu-button"
    id="menu-contracts-button"
    routerLink="/home/contracts"
    routerLinkActive="active-list-item"
  >
    <div class="icon-container">
      <em
        class="ph-file-text"
        matTooltip="Mes contrats"
        matTooltipPosition="right"
      ></em>
    </div>
    <span>Mes contrats</span>
  </li>

  <li
    [class.menu-close]="!isMenuOpen"
    [class.menu-open]="isMenuOpen"
    class="menu-button"
    id="menu-invoices-button"
    routerLink="/home/invoices"
    routerLinkActive="active-list-item"
  >
    <div class="icon-container">
      <em
        class="ph-currency-eur"
        matTooltip="Mes factures"
        matTooltipPosition="right"
      ></em>
    </div>
    <span>Mes factures</span>
  </li>

  <li
    [class.menu-close]="!isMenuOpen"
    [class.menu-open]="isMenuOpen"
    class="menu-button"
    id="menu-index-button"
    routerLink="/home/index"
    routerLinkActive="active-list-item"
  >
    <div class="icon-container">
      <em
        class="ph-lightning"
        matTooltip="Mes index"
        matTooltipPosition="right"
      ></em>
    </div>
    <span>Mes index</span>
  </li>

  <li
    [class.menu-close]="!isMenuOpen"
    [class.menu-open]="isMenuOpen"
    class="menu-button"
    id="menu-user-button"
    routerLink="/home/user"
    routerLinkActive="active-list-item"
  >
    <div class="icon-container">
      <em
        class="ph-user-circle"
        matTooltip="Mes informations personnelles"
        matTooltipPosition="right"
      ></em>
    </div>
    <span>Mes informations personnelles</span>
  </li>

  <li
    [class.menu-close]="!isMenuOpen"
    [class.menu-open]="isMenuOpen"
    class="menu-button"
    id="menu-step-button"
    routerLink="/home/step"
    routerLinkActive="active-list-item"
  >
    <div class="icon-container">
      <em
        class="ph-folder-notch-open"
        matTooltip="Mes Démarches"
        matTooltipPosition="right"
      ></em>
    </div>
    <span>Mes démarches</span>
  </li>

  <li
    [class.menu-close]="!isMenuOpen"
    [class.menu-open]="isMenuOpen"
    class="menu-button"
    id="menu-help-button"
    routerLink="/home/help"
    routerLinkActive="active-list-item"
  >
    <div class="icon-container">
      <em
        class="ph-lifebuoy"
        matTooltip="Besoin d'aide ?"
        matTooltipPosition="right"
        matTooltipClass="menu"
      ></em>
    </div>
    <span>Aide</span>
  </li>
  <div class="menu-spacer"></div>
</ul>
