<div class="next-index-event-container">
  <div class="next-index-title">
    <h3>Saisies à venir</h3>
  </div>
  <div class="table-container">
    <table
      [dataSource]="dataSource"
      class="default-table"
      id="index-table"
      mat-table
    >
      <caption></caption>
      <ng-container matColumnDef="contractLine.contractLineId">
        <th *matHeaderCellDef mat-header-cell>Contrat</th>
        <td
          *matCellDef="let element"
          class="default-cell default-column-type-semibold-center"
          mat-cell
        >
          {{element?.contractLine.contractLineId}}
        </td>
      </ng-container>

      <ng-container matColumnDef="ownerPartner.mainContact.fullName">
        <th *matHeaderCellDef mat-header-cell class="left">Titulaire</th>
        <td
          *matCellDef="let element"
          class="default-cell default-column-type-regular-left"
          mat-cell
        >
          {{element?.ownerPartner.mainContact.fullName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="contractLine.mpt.code">
        <th *matHeaderCellDef class="center" mat-header-cell>
          Point de comptage
        </th>
        <td
          *matCellDef="let element"
          class="default-cell default-column-type-semibold-left"
          mat-cell
        >
          {{element?.contractLine.mpt.code}}
        </td>
      </ng-container>

      <ng-container matColumnDef="contractLine.mpt.address.fullName">
        <th *matHeaderCellDef class="left" mat-header-cell>
          Adresse du point de comptage
        </th>
        <td
          *matCellDef="let element"
          class="default-cell default-column-type-regular-left"
          mat-cell
        >
          {{element?.contractLine.mpt.address.fullName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="theoreticalDateNextInvoice">
        <th *matHeaderCellDef mat-header-cell>Date TPF</th>
        <td
          *matCellDef="let element"
          class="default-cell date-column-type"
          mat-cell
        >
          {{ element?.theoreticalDateNextInvoice | date : "dd/MM/yyyy" }}
        </td>
      </ng-container>

      <tr *matHeaderRowDef="nextIndexHead; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let element; columns: nextIndexHead" mat-row></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="default-cell no-data" *ngIf="!isLoading" colspan="4">
          {{ noDataMessage }}
        </td>
        <ng-container *ngIf="isLoading">
          <td *ngFor="let column of nextIndexHead">
            <div
              class="loading-table default-cell default-column-type-regular-center"
            >
              <ngx-skeleton-loader [theme]="{ 'height.rem': 4 }" count="3">
              </ngx-skeleton-loader>
            </div>
          </td>
        </ng-container>
      </tr>
    </table>
  </div>
  <app-collapse
    title="En savoir plus sur la Date TPF"
    [hidden]="(isHandset$ | async)"
  >
    <app-report-message
      message="Date TPF signifie Date Théorique de Prochaine Facturation.<br><br>
      C’est la date à laquelle votre saisie d’index sera autorisée.<br><br>
      Une ligne s’affichera en bleu : La saisie sera donc ouverte.<br><br>
      Avant cette date et conformément à la règlementation de votre contrat d’achat, il est inutile de nous contacter pour inscrire votre index.<br><br>
      Par exception, nous enregistrerons l’index si un évènement est intervenu (un avenant, un changement de compteur …)">
    </app-report-message>
  </app-collapse>
</div>
