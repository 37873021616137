import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberService } from '@app/shared/services/phone-number.service';

@Pipe({
  name: 'phoneNumberFormat',
})
export class PhoneNumberFormatPipe implements PipeTransform {
  transform(number: string | undefined): string | undefined {
    return PhoneNumberService.formatPhoneNumber(number);
  }
}
