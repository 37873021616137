import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { ConfirmDialogData } from '@app/shared/components/dialog/dialog.data';
import { DialogService } from '@app/shared/components/dialog/dialog.services';
import { MESSAGES } from '@app/shared/utils/messages';
import { map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
})
export class LoginContainerComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );

  constructor(
    private dialogService: DialogService,
    private router: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit() {
    this.router.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((message) => {
      if (message.isPasswordModified) {
        this.alertService.success(MESSAGES.PASSWORD_MODIFIED);
      }
      if (message.isMailModified) {
        const options: ConfirmDialogData = {
          message: MESSAGES.CHANGE_MAIL,
          title: 'Votre email a été modifié',
          confirmText: 'Accéder à votre page de connexion',
          enableConfirmButton: true,
          confirmFunction: () => {
            this.dialogService.close();
          },
        };
        this.dialogService.open(options);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  protected readonly window = window;
}
