import { Component } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss'],
})
export class MainContentComponent {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );

  constructor(private breakpointObserver: BreakpointObserver) {}

  public slides = [
    {
      number: '65 %',
      content:
        '<p>Part de l’énergie photovoltaïque dans les nouvelles capacités électriques raccordées en 2023 dans le monde.</p>',
    },
    {
      number: '35,1 à 44 GW',
      content:
        '<p>Part de l’énergie photovoltaïque dans les nouvelles capacités électriques raccordées en 2023 dans le monde.</p>',
    },
    {
      number: '68,96 €/MWh',
      content:
        '<p>Prix moyen des centrales PV au sol (< 5MW) en France lors des 3 dernières période de l’appel d’offres PPE2.</p>',
    },
    {
      number: '95 %',
      content: '<p>Taux de valorisation des panneaux silicium (95% du marché mondial) en fin de vie.</p>',
    },
  ];

  protected readonly window = window;
}
