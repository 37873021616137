import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { Profile } from '@app/shared/interfaces/profile.interface';
import { User } from '@app/shared/models/user-info';
import { Observable, Subject, map, shareReplay, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  userConnected?: User;

  profile?: Profile;

  allProfiles: Profile[] = [];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(),
  );

  @Input() isMenuOpen: boolean = false;

  @Input() sidenav: any;

  constructor(
    private authentication: AuthService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.authentication.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });

    this.authentication.getProfile().subscribe({
      next: (profile: Profile) => {
        this.profile = profile;
      },
    });

    this.allProfiles = this.authentication.profiles;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  logout() {
    this.authentication.signOut();
  }

  protected readonly window = window;
}
