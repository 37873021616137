import { NgModule } from '@angular/core';
import { PhoneNumberFormatPipe } from '../pipes/phone-number-format.pipe';
import { FormControlPipe } from '../pipes/form-control.pipe';

const pipeModules = [PhoneNumberFormatPipe, FormControlPipe];

@NgModule({
  declarations: [...pipeModules],
  exports: [...pipeModules],
})
export class PipeModule {}
