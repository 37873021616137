<div class="new-index-event-container">
  <section class="section-container">
    <div class="section-content new-index-event-content">
      <div class="section-header">
        <div class="title-container">
          <h2 class="small-title" id="index-name">Ma nouvelle production</h2>
        </div>
      </div>

      <div *ngIf="loading" id="loader">
        <mat-spinner
          [diameter]="100"
          id="mat-spinner"
          class="index-spinner"
        ></mat-spinner>
        <span
          >Veuillez patienter, la validation de l'index est en cours ...</span
        >
      </div>
      <app-report-message
        [type] = "MessageReportLevel.WARNING"
        message="Si votre compteur a été changé récemment pour un <b>Linky</b>, nous
          vous remercions de ne rien saisir. Nos services mettront à jour vos
          index et vous en serez informé par mail. Toutefois si l’intervention
          date de plus d’un mois, veuillez nous le signaler via le formulaire de
          contact.">
      </app-report-message>
      <form
        *ngIf="!loading"
        (ngSubmit)="submit()"
        [formGroup]="newIndexEventForm"
        >
        <div class="content">
          <div class="form-container bordered">
            <div class="display-row">
              <div class="flex-1">
                <div class="text-input-container">
                  <mat-label class="label">Date de relevé</mat-label>
                  <app-datepicker
                    class="data"
                    dateLabel="Date de relevé"
                    [maxDate]="maxDate ? maxDate : null"
                    [minDate]="minDate ? minDate : null"
                    (dateValue)="setStatementDate($event)"
                    matDatepickerMaxLabel="La date du relevé doit être inférieure ou égale à la date du jour"
                    matDatepickerMinLabel="La date du relevé doit être supérieure à la date de début"
                    (hasError)="setStartDateError($event)"
                    [required]="true"
                    [date]="newIndexEventForm.get('date')! | formControl"
                    className="statementDate"
                  >
                  </app-datepicker>
                </div>

                <div class="text-input-container">
                  <p class="label">Durée de production</p>
                  <p class="data">
                    {{ getProductionTime() | number : "1.0-2" : "fr-FR" }}
                    jour(s)
                  </p>
                </div>

                <div class="text-input-container">
                  <mat-label class="label">Nouvel index</mat-label>
                  <div class="display-column">
                    <app-input
                      id="newIndex"
                      [formCtrl]="
                        newIndexEventForm.get('newIndex')! | formControl
                      "
                      type="number"
                      (inputValue)="setNewIndex($event)"
                      [errorMessages]="errorMessagesIndex"
                    >
                    </app-input>
                  </div>
                </div>

                <div class="text-input-container" *ngIf="this.displayMain">
                  <mat-label class="label">{{ this.index?.contractLine?.linkedContractLine ?
                    'Production du dossier principal ' + this.index?.contractLine?.contractLineId :
                    'Production '}} (kWh)</mat-label>
                  <div class="display-column">
                    <app-input
                      type="number"
                      [formCtrl]="
                        newIndexEventForm.get('production')! | formControl
                      "
                      (inputValue)="setProduction($event)"
                      [errorMessages]="errorMessagesProduction"
                      [readonly]="!!this.index?.linkedIndex"
                    >
                    </app-input>
                  </div>
                </div>

                <div class="text-input-container" *ngIf="this.displaySecondary">
                  <mat-label class="label">Production du dossier secondaire {{this.index?.contractLine?.linkedContractLine?.contractLineId}} (kWh)</mat-label>
                  <div class="display-column">
                    <app-input
                      type="number"
                      [formCtrl]="
                        newIndexEventForm.get('productionSecondaire')! | formControl
                      "
                      [errorMessages]="errorMessagesProduction"
                      [readonly]="true"
                    >
                    </app-input>
                  </div>
                </div>
                <app-report-message
                  message="L'index correspond au cumul de l'énergie produite.<br/>
                    La production correspond à la diffèrence entre ancien et nouvel index.">
                </app-report-message>
              </div>
              <div *ngIf="linkyValue" class="flex-1 cadre-bleu justify-form">
                <h4 class="text-center">
                  Télérelève des index de votre compteur Linky
                </h4>
                <div class="content-linky">
                  <p><b>Date</b> : {{ linkyDate.toFormat("dd/MM/yyyy") }}</p>
                  <p><b>Index</b> : {{ linkyValue }}</p>
                </div>
                <div class="text-center button-bottom">
                  <button
                    mat-flat-button
                    class="default-btn"
                    type="button"
                    (click)="acceptTelereleve()"
                  >
                    Accepter la télérelève
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="linkyValue">
              <app-report-message
                message="Si vous êtes d'accord avec la télérelève des index de compteur
                Linky, vous pouvez l'accepter, sinon renseigner votre nouvel
                index. Dans les deux cas il faudra valider votre production.">
              </app-report-message>
            </div>
          </div>
          <div class="price-container">
            <div class="price-production">
              <app-price-production
                *ngIf="this.displayMain"
                [production]="f.production.value"
                [priceMajUnder]="this.price"
                [category]="this.index?.contractLine?.linkedContractLine ? Category.PRINCIPAL : Category.UNIQUE"
                [numeroDossier]="this.index?.contractLine?.contractLineId"
              />
              <app-price-production
                *ngIf="this.displaySecondary"
                [production]="f.productionSecondaire.value"
                [priceMajUnder]="this.linkedPrice"
                [category]="Category.SECONDARY"
                [numeroDossier]="this.index?.contractLine?.linkedContractLine?.contractLineId"
              />
            </div>
            <app-report-message
              message="Montant estimatif ne comprenant pas les prestations du
                Gestionnaire de Réseau de Distribution ainsi que les éventuelles
                primes">
            </app-report-message>

            <div class="submit-form">
              <button
                class="default-btn"
                id="newIndexEventBtn"
                mat-flat-button
                type="submit"
                [disabled]="
                  f.newIndex.invalid ||
                  f.production.invalid ||
                  dateError ||
                  loading
                "
              >
                <span id="button-text">Valider la production</span>
                <i class="ph ph-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</div>
