export class Alert {
  id: string = '';

  type!: AlertType;

  action?: string;

  message: string = '';

  icon?: string = '';

  duration?: number = 10000;

  horizontalPosition: string = 'center';

  verticalPosition: string = 'bottom';

  keepAfterNavigationChange: boolean = false;

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning,
}
