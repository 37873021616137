import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { ConfirmDialogData } from '@app/shared/components/dialog/dialog.data';
import { DialogService } from '@app/shared/components/dialog/dialog.services';
import { PaginatorService } from '@app/shared/components/paginator/paginator.service';
import { IndexDataResponse, IndexResponse } from '@app/shared/interfaces/index.interface';
import { MESSAGES } from '@app/shared/utils/messages';
import { Subject, takeUntil } from 'rxjs';
import { DataColumns } from '@app/shared/interfaces/table.interface';
import { state } from './helper';
import { IndexEventService } from './indexEvent.service';

@Component({
  selector: 'app-index',
  templateUrl: './indexEvent.component.html',
  styleUrls: ['./indexEvent.component.scss'],
})
export class IndexEventComponent implements OnInit, OnDestroy, AfterViewInit {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  @Input() searchIndex: FormControl = new FormControl();

  @ViewChild('dialogNextIndexContent')
  private dialogNextIndexContent: TemplateRef<unknown>;

  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;

  dataColumns: DataColumns[] = [
    {
      def: 'contractLineId',
      header: 'Dossier',
      id: 'contractLineId',
      type: 'transform',
      transform: this.transformContractLineId,
      class: 'default-cell default-column-type-semibold-center',
    },
    {
      def: 'ownersPartner',
      header: 'Titulaire',
      id: 'ownerPartner',
      class: 'default-cell default-column-type-regular-center',
    },
    { def: 'mptCode', header: 'Contrat', id: 'mptCode', class: 'default-cell default-column-type-semibold-left' },
    {
      def: 'mptAddress',
      header: 'Adresse du point de comptage',
      id: 'mptAddress',
      class: 'default-cell default-column-type-regular-center',
    },
    {
      def: 'theoreticalDateNextInvoice',
      header: 'Date TPF',
      type: 'date',
      id: 'theoreticalDateNextInvoice',
      class: 'default-cell date-column-type',
    },
    {
      def: 'fromDate',
      header: 'Date début index',
      type: 'date',
      id: 'fromDate',
      class: 'default-cell date-column-type',
    },
    {
      def: 'indexDate',
      header: 'Date de relevé',
      type: 'date',
      id: 'indexDate',
      class: 'default-cell date-column-type',
    },
    {
      def: 'oldIndexValue',
      header: 'Ancien index',
      type: 'transform',
      transform: this.transformOldIndexValue.bind(this),
      id: 'oldIndexValue',
      class: 'default-cell default-column-type-semibold-center',
    },
    {
      def: 'indexValue',
      header: 'Nouvel index',
      type: 'transform',
      transform: this.transformIndexValue.bind(this),
      id: 'indexValue',
      class: 'default-cell default-column-type-semibold-center',
    },
    {
      def: 'production',
      header: 'Production',
      type: 'transform',
      transform: this.transformProduction,
      id: 'production',
      class: 'default-cell default-column-type-semibold',
      unit: 'kWc',
    },
    {
      def: 'edit',
      header: 'Saisie',
      type: 'custom',
      id: 'saisie',
      class: 'default-cell default-column-type-semibold-center',
      customTemplate: undefined,
    },
  ];

  state = state;

  dataSource: MatTableDataSource<IndexDataResponse> = new MatTableDataSource<IndexDataResponse>();

  resultLimit: number = 10;

  resultOffset: number = 0;

  totalIndexNumber: number = 0;

  noDataMessage: string = '';

  isLoading: boolean = true;

  constructor(
    private dialogService: DialogService,
    private authentication: AuthService,
    private router: Router,
    private indexService: IndexEventService,
    private paginatorService: PaginatorService,
    private alertService: AlertService,
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataColumns.find((col) => col.id === 'saisie')!.customTemplate = this.statusTemplate;
  }

  ngOnInit(): void {
    this.initPaginator();
    this.initIndex();
  }

  initIndex() {
    this.authentication.authLoader$.pipe(takeUntil(this.unsubscribe$)).subscribe((authLoader) => {
      if (!authLoader) {
        this.getIndex();
      }
    });
  }

  initPaginator() {
    this.paginatorService.limit.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (limit) => {
        this.resultLimit = limit;
      },
    });

    this.paginatorService.page.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (page) => {
        this.resultOffset = (page - 1) * this.resultLimit;
        this.getIndex();
      },
    });
  }

  clickedRow(row: IndexDataResponse, $event: Event) {
    $event.stopPropagation();
    if (['close', 'open'].includes(state(row))) this.router.navigate([`/home/index/${row.id}`]);
  }

  ngOnDestroy(): void {
    this.paginatorService.clear();
    this.dataSource.data = [];
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  getIndex(sortBy = '-theoreticalDateNextInvoice'): void {
    this.isLoading = true;
    this.indexService
      .getIndex({
        limit: this.resultLimit,
        offset: this.resultOffset,
        search: this.searchIndex.value,
        emailSentOk: true,
        invoicedOk: true,
        emailSentOrInvoiced: true,
        sortBy,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: IndexResponse) => {
          this.paginatorService.itemProperties$.next({
            total: response.total,
            shown: response.data ? response.data.length : 0,
          });
          this.totalIndexNumber = response.total;
          if (response.data)
            this.dataSource.data = response.data.map((item) => ({
              ...item,
              contractLineId: item.contractLine.contractLineId,
              ownersPartner: item.ownerPartner?.mainContact.fullName,
              mptCode: item.contractLine.mpt?.code,
              mptAddress: item.contractLine.mpt?.address.fullName,
            }));
          else {
            this.dataSource.data = [];
            this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          }
          this.isLoading = false;
        },
        error: () => {
          this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          this.alertService.error(MESSAGES.DEFAULT_ERROR);
          this.isLoading = false;
        },
      });
  }

  announceSortChange(sortState: Sort): void {
    if (sortState.direction) {
      if (sortState.direction === 'asc') {
        this.getIndex(`-${sortState.active}`);
      } else {
        this.getIndex(sortState.active);
      }
    } else {
      this.getIndex();
    }
  }

  openDialog(): void {
    const options: ConfirmDialogData = {
      confirmText: 'Fermer',
      enableConfirmButton: true,
      dialogContent: this.dialogNextIndexContent,
      confirmFunction: () => {
        this.dialogService.close();
      },
    };
    this.dialogService.open(options);
  }

  transformContractLineId(element: IndexDataResponse): string {
    const contractLineId: string = element?.contractLine?.contractLineId || '';
    const linkedContractLineId: string = element?.linkedIndex?.contractLine?.contractLineId || '';
    return linkedContractLineId ? `${contractLineId}/${linkedContractLineId}` : contractLineId;
  }

  transformIndexValueGeneric(element: any, property: string, linkedProperty: string): string {
    const value = element?.[property] || 0;
    const linkedValue = element?.linkedIndex?.[linkedProperty] || 0;
    const totalValue = value + linkedValue;
    return totalValue.toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }

  transformOldIndexValue(element: IndexDataResponse): string {
    return this.transformIndexValueGeneric(element, 'oldIndexValue', 'oldIndexValue');
  }

  transformIndexValue(element: IndexDataResponse): string {
    return this.transformIndexValueGeneric(element, 'indexValue', 'indexValue');
  }

  transformProduction(element: IndexDataResponse): string {
    if (!element.production) {
      return '';
    }

    const production = element.production || 0;
    const linkedProduction = element.linkedIndex?.production || 0;
    const totalProduction = production + linkedProduction;

    return `${totalProduction.toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} kWc`;
  }
}
