export enum ButtonType {
  primaryBtn = 'primaryBtn',
  blankBtn = 'blankBtn',
  blankBackBtn = 'blankBackBtn',
  iconBtn = 'iconBtn',
  iconTextBtn = 'iconTextBtn',
  backBtn = 'backBtn',
  borderedBackBtn = 'borderedBackBtn',
}

export enum ButtonForm {
  square = 'square',
  circle = 'circle',
}

export enum IconDirection {
  right = 'right',
  left = 'left,',
}

export type ButtonTypeString = keyof typeof ButtonType;
export type ButtonFormString = keyof typeof ButtonForm;
export type IconDirectionString = keyof typeof IconDirection;
