<nav class="login-header d-flex" xmlns="http://www.w3.org/1999/html">
  <img
    alt="alterna_logo"
    class="img-logo"
    src="https://static.soregies.fr/logos/Groupe%20Sor%C3%A9gies/Logos%20Groupe%20Soregies/Logo%20Groupe%20Soregies/Logo%20Groupe%20Soregies.png"
  />
</nav>

<div class="container-loading">
  <div class="text-loading">
    Chargement en cours
  </div>
</div>
