import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { MESSAGES } from '@app/shared/utils/messages';

@Injectable({
  providedIn: 'root',
})
export class ProfileGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> | boolean {
    if (this.authService.isLoggedIn()) {
      if (this.authService.isSessionExpired()) {
        this.authService.signOut();
        this.alertService.info(MESSAGES.EXPIRED_SESSION, {
          icon: 'sign-out',
          duration: 30000,
        });
        return false;
      }

      if (this.authService.profiles.length === 1) {
        this.authService.setProfile(this.authService.profiles[0]);
        this.router.navigate(['/home']);
        return false;
      }

      if (this.authService.profiles.length === 0) {
        this.router.navigate(['/contact-not-found-page']);
        return false;
      }

      return true;
    }
    this.router.navigate(['']);
    return false;
  }

  canActivateChild(): Observable<boolean | UrlTree> | boolean {
    return this.canActivate();
  }
}
