import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  public filesHolder$ = new BehaviorSubject<File[]>([]);

  public addFiles(files: File[]) {
    this.filesHolder$.next([...this.filesHolder$.value, ...files]);
  }

  public removeFile(index: number) {
    const files = this.filesHolder$.value.slice();
    files.splice(index, 1);
    this.filesHolder$.next(files);
  }

  public cleanFiles() {
    const files: File[] = [];
    this.filesHolder$.next(files);
  }

  get filesHolder(): File[] {
    return this.filesHolder$.getValue();
  }

  public getSize() {
    let size = 0;
    this.filesHolder$.subscribe((files: File[]) => {
      size = files.reduce((a, c) => a + c.size, 0);
    });
    return size;
  }
}
