<div class="user-container">
  <section class="section-container">
    <div class="section-content info-card">
      <div class="section-header">
        <div class="title-container">
          <h3>Mes identifiants</h3>
        </div>
      </div>
      <div class="info-datas">
        <div>
          <div class="one-line">Email de connexion</div>
          <div class="one-line">
            <span>{{ this.userConnected?.email }}</span>
          </div>
          <button
            (click)="openMailChangeModal()"
            class="user-info-button"
            type="button"
            mat-flat-button
          >
            Modifier mon email
            <i class="ph ph-pencil-simple"></i>
          </button>
          <ng-template
            #dialogChangeMailContent
            let-setEnable="setEnable"
            let-setLoading="setLoading"
          >
            <app-change-mail [setEnable]="setEnable" [setLoading]="setLoading">
            </app-change-mail>
          </ng-template>
        </div>
        <div>
          <p>Mot de passe</p>
          <button
            (click)="openChangePasswordDialog()"
            class="user-info-button"
            type="button"
            mat-flat-button
          >
            Modifier mon mot de passe
            <i class="ph ph-pencil-simple"></i>
          </button>

          <ng-template
            #dialogChangePasswordContent
            let-setEnable="setEnable"
            let-setLoading="setLoading"
          >
            <app-change-password
              [setEnable]="setEnable"
              [setLoading]="setLoading"
            >
            </app-change-password>
          </ng-template>
        </div>
        <div></div>
        <div></div>
      </div>
    </div>
  </section>
  <section class="section-container">
    <div class="section-content info-card">
      <div class="section-header">
        <div class="title-container">
          <h3>Mes coordonnées</h3>
        </div>
      </div>
      <div class="info-content">
        <div class="main-information-grid">
          <app-main-information
            title="Nom"
            [value]="profile?.fullName"
            id="name"
            [loading]="isLoadingProfile"
          ></app-main-information>
          <app-main-information
            title="Téléphone mobile"
            [value]="formatPhoneNumber(profile?.mobilePhone)"
            id="mobilePhone"
            [loading]="isLoadingProfile"
          ></app-main-information>
          <app-main-information
            title="Téléphone fixe"
            [value]="formatPhoneNumber(profile?.fixedPhone)"
            id="fixedPhone"
            [loading]="isLoadingProfile"
          ></app-main-information>

          <div id="action">
            <button
              (click)="openChangeUserInfoDialog()"
              class="modify-button"
              type="button"
              mat-flat-button
            >
              Modifier mes coordonnées
              <i class="ph ph-pencil-simple"></i>
            </button>
            <ng-template
              #dialogChangeUserInfoContent
              let-setEnable="setEnable"
              let-setLoading="setLoading"
            >
              <app-change-user-info
                [setEnable]="setEnable"
                [setLoading]="setLoading"
                [data]="{ contact: this.profile }"
              >
              </app-change-user-info>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="profile?.managerOk" class="section-container">
    <div class="section-content info-card">
      <div class="section-header">
        <div class="title-container">
          <h3>Mes producteurs</h3>
        </div>
      </div>
      <div class="info-datas">
        <table
          [dataSource]="this.ownerPartners"
          class="default-table"
          id="user-info-table"
          mat-table
        >
          <caption></caption>
          <ng-container matColumnDef="name">
            <th *matHeaderCellDef class="left" mat-header-cell>Nom</th>
            <td
              *matCellDef="let element"
              class="default-cell default-column-type-semibold-left name"
              mat-cell
            >
              {{ element?.fullName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="mobilePhone">
            <th *matHeaderCellDef class="left" mat-header-cell>
              Téléphone mobile
            </th>
            <td
              *matCellDef="let element"
              class="default-cell default-column-type-regular-left mobilePhone"
              mat-cell
            >
              {{ formatPhoneNumber(element?.mobilePhone) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="fixedPhone">
            <th *matHeaderCellDef class="left" mat-header-cell>
              Téléphone fixe
            </th>
            <td
              *matCellDef="let element"
              class="default-cell default-column-type-regular-left fixedPhone"
              mat-cell
            >
              {{ formatPhoneNumber(element?.fixedPhone) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th *matHeaderCellDef class="left" mat-header-cell>Email</th>
            <td
              *matCellDef="let element"
              class="default-cell default-column-type-regular-left email"
              mat-cell
            >
              {{ element?.email }}
            </td>
          </ng-container>
          <tr
            *matHeaderRowDef="contactDetailsHead; sticky: true"
            mat-header-row
          ></tr>
          <tr
            *matRowDef="let element; columns: contactDetailsHead"
            mat-row
            (click)="($event ? null : null)"
          ></tr>

          <tr class="mat-row" *matNoDataRow>
            <td
              class="default-cell no-data"
              *ngIf="!contactDetailsLoading"
              colspan="4"
            >
              {{ noOwnerPartnerMessage }}
            </td>
            <ng-container *ngIf="contactDetailsLoading">
              <td *ngFor="let column of contactDetailsHead">
                <div
                  class="loading-table default-cell default-column-type-regular-center"
                >
                  <ngx-skeleton-loader [theme]="{ 'height.rem': 2 }" count="3">
                  </ngx-skeleton-loader>
                </div>
              </td>
            </ng-container>
          </tr>
        </table>
        <div></div>
        <div></div>
      </div>
    </div>
  </section>

  <section class="section-container">
    <div class="section-content info-card">
      <div class="section-header">
        <div class="title-container">
          <h3>Mes points de comptage</h3>
        </div>
      </div>
      <div class="info-datas">
        <table
          mat-table
          [dataSource]="dataSource"
          class="default-table"
          id="pct-table"
        >
          <caption></caption>
          <ng-container matColumnDef="mptCode">
            <th *matHeaderCellDef class="left" mat-header-cell>N° de contrat</th>
            <td
              *matCellDef="let element"
              class="default-cell default-column-type-semibold-left id-pct"
              mat-cell
            >
              {{ element?.mpt.code }}
            </td>
          </ng-container>

          <ng-container matColumnDef="mptAddress">
            <th *matHeaderCellDef class="left" mat-header-cell>Adresse</th>
            <td
              *matCellDef="let element"
              class="default-cell default-column-type-regular-left address-pct"
              mat-cell
            >
              {{ element?.mpt.address }}
            </td>
          </ng-container>

          <ng-container matColumnDef="mptPeakPower">
            <th *matHeaderCellDef class="left" mat-header-cell>
              Puissance crête
            </th>
            <td
              *matCellDef="let element"
              class="default-cell default-column-type-regular-left peak-power"
              mat-cell
            >
              {{ element?.peakPower ?? 0 | number : "1.0-2" : "fr-FR" }} kWc
            </td>
          </ng-container>

          <tr *matHeaderRowDef="pctHead; sticky: true" mat-header-row></tr>
          <tr
            *matRowDef="let element; columns: pctHead"
            mat-row
            (click)="($event ? null : null)"
          ></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="default-cell no-data" *ngIf="!isLoading" colspan="4">
              {{ noDataMessage }}
            </td>
            <ng-container *ngIf="isLoading">
              <td *ngFor="let column of pctHead">
                <div
                  class="loading-table default-cell default-column-type-regular-center"
                >
                  <ngx-skeleton-loader [theme]="{ 'height.rem': 2 }" count="3">
                  </ngx-skeleton-loader>
                </div>
              </td>
            </ng-container>
          </tr>
        </table>
      </div>
    </div>
  </section>
  <mat-card class="info-card">
    <div class="data">
      <span><strong>Données personnelles :</strong></span>
      <p>
        Conformément au contrat d’obligation d’achat, SOREGIES - Responsable des
        Traitements des données personnelles - est autorisée à échanger
        uniquement avec le GRD les données de comptage et les données
        d’identification (adresse, adresse mail, n° de téléphone) dans le but de
        faciliter la gestion contractuelle, la facturation et les interventions
        du GRD (exemple : panne compteur, …). Ces données ne sont ni destinées à
        un usage commercial ni communiquées à des tiers. Elles sont conservées
        pendant toute la durée du contrat et 5 ans après sa résiliation, sauf
        pour les données de facturation qui sont conservées 9 ans.
        <br /><br />
        Conformément à la loi Informatique et libertés, vous disposez d’un droit
        d’accès, de rectification, et d’opposition pour motifs légitimes
        relativement à l’ensemble des données vous concernant, qui s’exercent
        auprès du DPO de Sorégies
        <a href="mailto:dpo@soregies.fr">dpo@soregies.fr</a> . Vous disposez du
        droit d’introduire une réclamation auprès de la CNIL, sur
        <a href="http://www.cnil.fr/">www.cnil.fr</a>.
      </p>
    </div>
  </mat-card>
</div>
