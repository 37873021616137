import { Color } from '@app/shared/models/color';
import { Component, Input } from '@angular/core';
import { MessageReportLevel } from '@app/shared/report-message/report-message.model';

@Component({
  selector: 'app-report-message',
  templateUrl: './report-message.component.html',
  styleUrls: ['./report-message.component.scss'],
})
export class ReportMessageComponent {
  @Input() type: MessageReportLevel = MessageReportLevel.INFO;

  @Input() message: string = '';

  getPhosphorClassAndColor(): { class: string; color: Color } {
    switch (this.type) {
      case MessageReportLevel.ERROR:
        return { class: 'ph-warning-circle', color: Color.danger500 };
      case MessageReportLevel.WARNING:
        return { class: 'ph-warning', color: Color.warning500 };
      default:
        return { class: 'ph-info', color: Color.info500 };
    }
  }
}
