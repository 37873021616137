import { ContractDetails } from '@app/shared/interfaces/contract.interface';
import { ContractLine } from '../interfaces/index.interface';

export const getPriceMajUnder = (contractDetails: ContractDetails | ContractLine | null): number => {
  if (contractDetails) {
    const { participatoryFundingEngagement, participatoryInvestmentEngagement, energyBuyoutPrice } = contractDetails;
    const funding = participatoryFundingEngagement ? Number(participatoryFundingEngagement) : 0;
    const investment = participatoryInvestmentEngagement ? Number(participatoryInvestmentEngagement) : 0;
    const price = energyBuyoutPrice ? Number(energyBuyoutPrice) : 0;
    return funding + investment + price;
  }
  return 0;
};

export const getPriceMajBeyond = (contractDetails: ContractDetails | null): number => {
  if (contractDetails) {
    const { participatoryFundingEngagement, participatoryInvestmentEngagement, energyBuyoutPriceBeyondCap } =
      contractDetails;
    const funding = participatoryFundingEngagement ? Number(participatoryFundingEngagement) : 0;
    const investment = participatoryInvestmentEngagement ? Number(participatoryInvestmentEngagement) : 0;
    const price = energyBuyoutPriceBeyondCap ? Number(energyBuyoutPriceBeyondCap) : 0;
    return funding + investment + price;
  }
  return 0;
};
