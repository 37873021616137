import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ChangeMailComponent } from '@app/components/user-info/change-mail/change-mail.component';
import { ChangePasswordComponent } from '@app/components/user-info/change-password/change-password.component';
import { ChangeUserInfoComponent } from '@app/components/user-info/change-user-info/change-user-info.component';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { ConfirmDialogData } from '@app/shared/components/dialog/dialog.data';
import { DialogService } from '@app/shared/components/dialog/dialog.services';
import { IOwnerPartner, IOwnerPartnerDetails } from '@app/shared/interfaces/owner-partner.interface';
import { Profile } from '@app/shared/interfaces/profile.interface';
import { User } from '@app/shared/models/user-info';
import { PhoneNumberService } from '@app/shared/services/phone-number.service';
import { MESSAGES } from '@app/shared/utils/messages';
import { Subject, takeUntil } from 'rxjs';
import { UserInfoService } from './user-info.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnDestroy, OnInit {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  userConnected?: User;

  dataSource = new MatTableDataSource<unknown>();

  pctHead = ['mptCode', 'mptAddress', 'mptPeakPower'];

  contactDetailsHead = ['name', 'mobilePhone', 'fixedPhone', 'email'];

  isLoading: boolean = true;

  isLoadingProfile: boolean = true;

  contactDetailsLoading: boolean = true;

  noDataMessage: string = '';

  noOwnerPartnerMessage: string = '';

  formatPhoneNumber = PhoneNumberService.formatPhoneNumber;

  ownerPartners = new MatTableDataSource<IOwnerPartnerDetails>();

  profile: Profile | undefined = undefined;

  @ViewChild('dialogChangeMailContent')
  private dialogChangeMailContent: TemplateRef<unknown>;

  @ViewChild('dialogChangePasswordContent')
  private dialogChangePasswordContent: TemplateRef<unknown>;

  @ViewChild('dialogChangeUserInfoContent')
  private dialogChangeUserInfoContent: TemplateRef<unknown>;

  @ViewChild(ChangeMailComponent)
  childMail: ChangeMailComponent;

  @ViewChild(ChangePasswordComponent)
  childPassword: ChangePasswordComponent;

  @ViewChild(ChangeUserInfoComponent)
  childUserInfo: ChangeUserInfoComponent;

  constructor(
    private dialogService: DialogService,
    private alertService: AlertService,
    private authService: AuthService,
    private userInfoService: UserInfoService,
    private router: Router,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  openChangePasswordDialog(): void {
    const options: ConfirmDialogData = {
      confirmText: 'Valider',
      cancelText: 'Annuler',
      title: 'Modifier mon mot de passe',
      dialogContent: this.dialogChangePasswordContent,
      confirmFunction: () => {
        this.childPassword
          .submitForm()
          .then(() => {
            this.dialogService.close();
          })
          .catch(() => {});
      },
    };
    this.dialogService.open(options);
  }

  ngOnInit(): void {
    this.initPCT();
    this.initProfile();
  }

  initPCT() {
    this.authService.authLoader$.pipe(takeUntil(this.unsubscribe$)).subscribe((authLoader) => {
      if (!authLoader) {
        this.getPCT();
      }
    });
  }

  initProfile() {
    this.authService.getProfile().subscribe({
      next: (profile: Profile) => {
        this.profile = profile;
        this.isLoadingProfile = false;
        if (profile.managerOk) this.getOwnerPartners();
      },
    });
  }

  getPCT() {
    this.isLoading = true;
    this.userInfoService
      .getPCT({ partnerId: this.authService.getProfileID() })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (pct: any) => {
          if (pct.data) {
            this.dataSource.data = pct.data;
          } else this.noDataMessage = MESSAGES.NO_DATA_ERROR;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  getOwnerPartners() {
    this.contactDetailsLoading = true;
    this.userInfoService
      .getOwnerPartners({ partnerId: this.authService.getProfileID() })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (contactDetails: IOwnerPartner) => {
          if (contactDetails.data) {
            this.ownerPartners.data = contactDetails.data.filter(
              (contact) => this.authService.getProfileID() !== contact.id.toString(),
            );
          } else this.noOwnerPartnerMessage = MESSAGES.NO_DATA_ERROR;

          this.contactDetailsLoading = false;
        },
        error: () => {
          this.noOwnerPartnerMessage = MESSAGES.NO_DATA_ERROR;
          this.contactDetailsLoading = false;
        },
      });
  }

  openMailChangeModal() {
    const options: ConfirmDialogData = {
      confirmText: 'Valider',
      cancelText: 'Annuler',
      title: 'Modifier mon email',
      dialogContent: this.dialogChangeMailContent,
      confirmFunction: () => {
        this.childMail
          .confirm()
          .then((state) => {
            if (state) {
              this.dialogService.close();
              this.authService.signOut(true);
            }
          })
          .catch(() => {});
      },
    };
    this.dialogService.open(options);
  }

  openChangeUserInfoDialog() {
    const options: ConfirmDialogData = {
      confirmText: 'Valider',
      cancelText: 'Annuler',
      title: 'Modifier mes informations personnelles',
      dialogContent: this.dialogChangeUserInfoContent,
      confirmFunction: () => {
        this.childUserInfo
          .submitForm()
          .then(() => {
            this.dialogService.close();
            this.isLoadingProfile = true;
            this.authService.getProfile(true).subscribe({
              next: (profile: Profile) => {
                this.profile = profile;
                this.isLoadingProfile = false;
                this.alertService.success('Coordonnées modifiées avec succès');
              },
            });
          })
          .catch(() => {
            this.alertService.error('Problème de la modification des coordonnées');
          });
      },
    };
    this.dialogService.open(options);
  }

  handleHelp(): void {
    this.router.navigate(['/home/help']);
  }

  ngOnDestroy(): void {
    this.dataSource.data = [];
    this.unsubscribe$.unsubscribe();
  }
}
