import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { ChallengeName } from '@app/shared/interfaces/user-response.interface';
import { MESSAGES } from '@app/shared/utils/messages';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  public loading = false;

  public hiddenPassword = false;

  public errorMessage = '';

  public loginform: UntypedFormGroup = this.fb.group({
    login: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^([A-Z|a-z|0-9](\\.|_|-){0,1})+[A-Z|a-z|0-9]\\@([A-Z|a-z|0-9](\\.|-){0,1})+[A-Z|a-z|0-9]\\.[a-z]{2,63}$',
        ),
      ],
    ],
    password: ['', Validators.required],
  });

  errorMessagesEmail = [
    { name: 'required', value: "L'email est requis" },
    { name: 'email', value: "Le format de l'email n'est pas valide" },
  ];

  displayResendPassword = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private authentication: AuthService,
    public alertService: AlertService,
  ) {
    const routeErrorMessage = this.router.getCurrentNavigation()?.extras?.state?.errorMessage;
    if (routeErrorMessage) {
      this.errorMessage = routeErrorMessage;
      this.loading = false;
    }
  }

  get f() {
    return this.loginform.controls;
  }

  navigateToProfileSelection() {
    this.authentication.canNavigateToProfileSelection().subscribe({
      error: () => {
        this.authentication.emptySession();
        this.loading = false;
      },
    });
  }

  generationOk(event: boolean) {
    if (event) {
      this.alertService.success('Génération du mot de passe temporaire effectué avec succès');
    } else {
      this.errorMessage = MESSAGES.RESEND_PASSWORD_ERROR;
    }
  }

  submit() {
    const { login: loginControl, password: passwordControl } = this.loginform.controls;
    this.loading = true;
    this.errorMessage = '';
    this.authentication
      .signIn(this.loginform.get('login')?.value, this.loginform.get('password')?.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          if (response === ChallengeName.NEW_PASSWORD_REQUIRED) {
            this.router.navigate(['reset_password']);
          } else {
            this.navigateToProfileSelection();
          }
        },
        error: (err) => {
          this.displayResendPassword = err.error.status === 'FORCE_CHANGE_PASSWORD';
          this.loading = false;
          loginControl.setErrors({ badIds: true });
          passwordControl.setErrors({ badIds: true });
        },
      });
  }
}
