import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthenticationResult } from '@app/shared/interfaces/user-response.interface';
import { LoaderService } from '@app/shared/services/loader.service';
import { MESSAGES } from '@app/shared/utils/messages';
import { LoaderComponent } from '@app/shared/components/loader/loader.component';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-saml-callback',
  standalone: true,
  imports: [LoaderComponent, AsyncPipe, NgIf],
  templateUrl: './saml-callback.component.html',
})
export class SamlCallbackComponent implements OnInit, OnDestroy {
  errorMessage: string = '';

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
  ) {
    this.loaderService.showGlobal();
  }

  get loading$() {
    return this.loaderService.global();
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(filter((params: Params) => params.code)).subscribe({
      next: (params: Params) => {
        this.authService.clear();
        this.authService.samlAuth(params.code).subscribe({
          next: (res: AuthenticationResult) => {
            if (res) {
              this.authService.responseLoginSaml(res);
              this.redirectUserToProfileSelectionOrHome();
            }
          },
          error: () => {
            this.router.navigate(['/']);
          },
        });
      },
    });
  }

  ngOnDestroy(): void {
    this.loaderService.hideGlobal();
  }

  redirectUserToProfileSelectionOrHome(): void {
    this.authService.canNavigateToProfileSelection(true).subscribe({
      next: (canNavigate: boolean) => {
        this.loaderService.hideGlobal();
        if (!canNavigate) {
          this.router.navigate(['/']);
        }
      },
      error: () => {
        this.authService.emptySession();
        this.loaderService.hideGlobal();
        this.errorMessage = MESSAGES.CONNEXION_ERROR;
        this.router.navigate(['/']);
      },
    });
  }
}
