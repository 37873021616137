import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ErrorType } from '@app/shared/components/error-section/error.model';
import { IndexEventDetails } from '@app/shared/interfaces/index.interface';
import { of, Subject, switchMap } from 'rxjs';
import { isEditable } from '../helper';
import { IndexEventDetailsService } from './indexEvent-details.service';

@Component({
  selector: 'app-index-events-details',
  templateUrl: './indexEvent-details.component.html',
  styleUrls: ['./indexEvent-details.component.scss'],
})
export class IndexEventDetailsComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  indexEventDetails: IndexEventDetails | null = null;

  linkedIndex: IndexEventDetails | null = null;

  productionBreakdown: number;

  isLoading: boolean = false;

  isNewIndex: boolean = false;

  numeroDossierSecondaire: string | undefined = undefined;

  error: ErrorType;

  constructor(
    private route: ActivatedRoute,
    private indexEventDetailsService: IndexEventDetailsService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getIndex();
  }

  private getIndex() {
    this.route.paramMap
      .pipe(
        switchMap((pMap: ParamMap) => this.indexEventDetailsService.getIndex(pMap.get('id')!)),
        switchMap((index: IndexEventDetails) => {
          this.isNewIndex = this.state(index);
          this.indexEventDetails = index;

          this.numeroDossierSecondaire = this.isNewIndex
            ? this.indexEventDetails?.contractLine?.linkedContractLine?.contractLineId
            : this.indexEventDetails?.linkedIndex?.contractLine?.contractLineId;

          if (index.linkedIndex) {
            if (this.isNewIndex) {
              const breakdown = this.indexEventDetails.contractLine.productionBreakdown;
              this.productionBreakdown = parseFloat(breakdown);
            } else {
              const coef = index.production / (index.production + index.linkedIndex.production);
              this.productionBreakdown = coef * 100;
            }
          }
          return of(index);
        }),
      )
      .subscribe({
        next: () => {
          this.isLoading = false;
        },
        error: (err) => {
          this.error = err.status;
          this.isLoading = false;
        },
      });
  }

  state(index: IndexEventDetails) {
    if (index.invoicedOk) return false;
    if (!isEditable(index) || !index.emailSentOk) {
      this.location.back();
      return false;
    }
    return true;
  }

  getOldIndexValue() {
    let oldIndexValue = this.indexEventDetails?.oldIndexValue ?? 0;

    if (this.linkedIndex) oldIndexValue += this.linkedIndex.oldIndexValue;
    else {
      oldIndexValue += this.indexEventDetails?.linkedIndex?.oldIndexValue ?? 0;
    }

    return oldIndexValue;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  handleBack() {
    this.location.back();
  }
}
