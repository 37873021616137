import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LUXON_DATE_FORMATS } from '@app/app.module';

@Component({
  selector: 'app-main-information',
  template: `
    <div class="main-info-container">
      <div class="main-info-content" (click)="redirectTo()">
        <span class="label">{{ title }}</span>
        <ng-container *ngIf="!loading">
          <span *ngIf="value" id="{{ id }}" class="value">{{
            type !== 'date' ? value : (value | date: LUXON_DATE_FORMATS.display.dateInput)
          }}</span>
          <span *ngIf="!value" class="value">Non communiqué</span>
        </ng-container>
        <ng-container *ngIf="loading">
          <ngx-skeleton-loader class="skeleton-loader" count="1" appearance="line"></ngx-skeleton-loader>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./main-information.component.scss'],
})
export class MainInformationComponent {
  @Input() loading: boolean = false;

  @Input() id: string = '';

  @Input() title: string = '';

  @Input() type: 'date' | 'text';

  @Input() value: string | null | undefined = '';

  @Input() redirectUrl: string = '';

  constructor(private router: Router) {}

  redirectTo() {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
    }
  }

  protected readonly LUXON_DATE_FORMATS = LUXON_DATE_FORMATS;
}
