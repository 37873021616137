import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { MESSAGES } from '@app/shared/utils/messages';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
  ) {}

  canActivate() {
    // Login page

    if (this.authService.isLoggedIn()) {
      if (this.authService.isSessionExpired()) {
        this.authService.signOut();
        this.alertService.info(MESSAGES.EXPIRED_SESSION, {
          icon: 'sign-out',
          duration: 30000,
        });
        return true;
      }
      if (this.authService.getProfileID()) {
        this.router.navigate(['/home']);
        return false;
      }

      this.router.navigate(['/profiles']);
      return false;
    }
    return true;
  }
}
