<div class="main-container">
  <section *ngIf="!error" class="section-container">
    <div class="section-content">
      <div class="section-header">
        <app-buttons [type]="'borderedBackBtn'" (clickAction)="handleBack()"></app-buttons>
        <div class="title-container">
          <h1 class="title" id="contract-name">
            {{ !this.isHistory ? "Mon Contrat" : "Mon contrat avant cession" }}
          </h1>
        </div>
      </div>
      <div class="contract-content">
        <div class="part">
          <h3>Gestion du contrat n° {{ contractDetails?.mpt?.code }}</h3>
          <div class="main-information-grid">
            <app-main-information id="contract" title="Dossier" [value]="contractDetails?.contractLineId"
              [loading]="isLoading"></app-main-information>
            <app-main-information id="holder" title="Titulaire" [value]="contractDetails?.ownerPartner?.name"
              [loading]="isLoading"></app-main-information>
            <app-main-information id="mpt" title="Contrat" [value]="contractDetails?.mpt?.code"
              [loading]="isLoading"></app-main-information>
            <app-main-information id="mptAddress" title="Adresse du point de comptage"
              [value]="contractDetails?.mpt?.address?.fullName" [loading]="isLoading"></app-main-information>
            <app-main-information id="supplyStartDate" title="Date de prise d'effet du contrat" [type]="'date'"
              [value]="contractDetails?.startDate" [loading]="isLoading"></app-main-information>
            <app-main-information id="supplyEndDate" title="Date de fin" [type]="'date'"
              [value]="contractDetails?.endDate" [loading]="isLoading"></app-main-information>
            <app-main-information id="decreeDate" title="Arrêté" [value]="contractDetails?.decree?.name"
              [loading]="isLoading"></app-main-information>
            <app-main-information *ngIf="contractDetails?.mpt?.exchangeRef" id="exchangeRef" title="Numéro de PRM"
              [value]="contractDetails?.mpt?.exchangeRef" [loading]="isLoading"></app-main-information>
            <div></div>
            <app-main-information id="billingRate" title="Rythme de facturation" [value]="
                this.billingRythm(contractDetails?.invoicingRhythmSelect)
              " [loading]="isLoading"></app-main-information>
            <app-main-information id="salesType" title="Type de vente"
              [value]="this.salesType(contractDetails?.salesTypeSelect)" [loading]="isLoading"></app-main-information>
            <app-main-information id="peakPower" title="Puissance crête" [value]="
                (contractDetails?.peakPower ?? 0 | number : '1.0-2' : 'fr-FR') +
                ' kWc'
              " [loading]="isLoading"></app-main-information>
            <app-main-information *ngIf="!this.isHistory" id="status" title="Statut"
              [value]="contractDetails?.status?.name" [loading]="isLoading"></app-main-information>
          </div>
        </div>
      </div>
      <div class="subpart-content" *ngIf="displayPrime()">
        <div class="part">
          <h3>Prime surplus</h3>
          <div class="main-information-grid">
            <app-main-information id="bonusOnMore" title="Prime surplus" [value]="contractDetails?.reward"
              [loading]="isLoading"></app-main-information>
            <app-main-information id="remainingBonusOnMore" title="Prime surplus restante"
              [value]="contractDetails?.rewardRemaining" [loading]="isLoading"></app-main-information>
          </div>
        </div>
      </div>
      <div class="subpart-content" *ngIf="!displayPrime()">
        <div class="part">
          <h3>Coefficient L</h3>
          <div class="main-information-grid">
            <app-main-information id="coeffL" title="Valeur coefficient L" [value]="contractDetails?.activeLValue"
              [loading]="isLoading"></app-main-information>
          </div>
        </div>
      </div>
      <div class="subpart-content" *ngIf="!this.isHistory">
        <div class="part">
          <h3>Prix de rachat de l'énergie</h3>
          <div class="main-information-grid">
            <app-main-information id="currentRepurchasePriceBelowCap" title="Prix de rachat actuel sous le plafond"
              [value]="contractDetails?.energyBuyoutPrice + ' €/kWh'" [loading]="isLoading"></app-main-information>
            <app-main-information id="currentRepurchasePriceAboveCap" title="Prix de rachat actuel au-dessus du plafond"
              [value]="contractDetails?.energyBuyoutPriceBeyondCap + ' €/kWh'"
              [loading]="isLoading"></app-main-information>
            <app-main-information id="engInvest" title="Engagement à l\'investissement participatif" [value]="
                contractDetails?.participatoryInvestmentEngagement + ' €/kWh'
              " [loading]="isLoading" *ngIf="contractDetails?.decree?.code === 'FV16'"></app-main-information>
            <app-main-information title="Engagement à un financement participatif" [value]="
                contractDetails?.participatoryFundingEngagement + ' €/kWh'
              " id="engFund" [loading]="isLoading"
              *ngIf="contractDetails?.decree?.code === 'FV16'"></app-main-information>
            <app-main-information title="Prix majoré de rachat actuel sous le plafond"
              [value]="getPriceMajUnder(contractDetails) + ' €/kWh'" id="priceMajUnder" [loading]="isLoading"
              *ngIf="contractDetails?.decree?.code === 'FV16'"></app-main-information>
            <app-main-information title="Prix majoré de rachat actuel au-dessus du plafond"
              [value]="getPriceMajBeyond(contractDetails) + ' €/kWh'" id="priceMajBeyond" [loading]="isLoading"
              *ngIf="contractDetails?.decree?.code === 'FV16'"></app-main-information>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="
      !error &&
      !displayPrime() &&
      !this.isHistory
    " class="section-container">
    <div class="section-content">
      <div class="section-header">
        <div class="title-container">
          <h3 class="title">Historique sur les années précédentes</h3>
        </div>
      </div>
      <div class="table-container">
        <table mat-table [dataSource]="priceHistory" class="default-table" id="test">
          <caption></caption>
          <ng-container matColumnDef="historyDate">
            <th *matHeaderCellDef class="left" mat-header-cell>Date d'historisation</th>
            <td *matCellDef="let element" class="default-cell default-column-type-semibold-left" mat-cell>
              {{ element.historyDate | date : "dd/MM/yyyy" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="coeffL">
            <th *matHeaderCellDef class="left" mat-header-cell>
              Valeur coefficient L
            </th>
            <td *matCellDef="let element" class="default-cell default-column-type-regular-left" mat-cell>
              {{ element.coeffL }}
            </td>
          </ng-container>
          <ng-container matColumnDef="buyoutPrice">
            <th *matHeaderCellDef class="left" mat-header-cell>
              Prix de rachat sous le plafond
            </th>
            <td *matCellDef="let element" class="default-cell default-column-type-regular-left" mat-cell>
              {{ element.buyoutPrice + " €/kWh" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="buyoutPriceBeyondCap">
            <th *matHeaderCellDef class="left" mat-header-cell>
              Prix de rachat au-dessus du plafond
            </th>
            <td *matCellDef="let element" class="default-cell default-column-type-regular-left" mat-cell>
              {{ element.buyoutPriceBeyondCap + " €/kWh" }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </section>
  <app-error-section *ngIf="error" [error]="error"></app-error-section>
</div>