import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/auth/auth.service';

@Component({
  selector: 'app-resend-password',
  templateUrl: './resend-password.component.html',
  styleUrls: ['./resend-password.component.scss'],
})
export class ResendPasswordComponent {
  loading: boolean = false;

  @Input() email: string;

  @Output() generationOk = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private authentication: AuthService,
  ) {}

  generatePassword() {
    this.loading = true;
    this.authentication.generateNewPassword(this.email).subscribe({
      next: () => {
        this.loading = false;
        this.generationOk.emit(true);
      },
      error: () => {
        this.loading = false;
        this.generationOk.emit(false);
      },
    });
  }
}
