import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaginatorService {
  page = new Subject<number>();

  limit = new Subject<number>();

  disabled = new Subject<boolean>();

  itemProperties$ = new BehaviorSubject({ total: 0, shown: 0 });

  clear(): void {
    this.page.complete();
    this.page = new Subject<number>();
    this.limit.complete();
    this.limit = new Subject<number>();
    this.itemProperties$.next({ total: 0, shown: 0 });
  }
}
