<div class="checkbox-content">
  <label class="option">
    <input type="checkbox"
           [attr.aria-label]="ariaLabel"
           [disabled]="disabled"
           [checked]="checked"
           [indeterminate]="indeterminate"
           (change)="toggleCheckbox()">
    <span
      class="checkbox checkbox-white-background"
      [class.checked]="checked && !indeterminate"
      [class.disabled]="disabled"
      [class.indeterminate]="indeterminate">
    </span>
  </label>
  <span #ref
        [class.text]="ref.innerHTML.trim()"
        [class.disabled]="disabled"
        (click)="toggleCheckbox()"
        (keydown.enter)="toggleCheckbox()"
        tabindex="0"
        role="button">
    <ng-content></ng-content>
  </span>
</div>
