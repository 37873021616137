<div class="index-container">
  <div class="index-header">
    <div class="title-container">
      <h1 class="index-title">Tous mes index</h1>
    </div>
    <div class="actions-header">
      <button
        (click)="openDialog()"
        class="next-index-btn"
        type="button"
        mat-flat-button
      >
        <i class="ph ph-binoculars"></i>
        Saisies à venir
      </button>

      <ng-template #dialogNextIndexContent>
        <app-next-index-event> </app-next-index-event>
      </ng-template>
    </div>
  </div>
  <div class="text-input-container">
    <mat-form-field
      hideRequiredMarker
      appearance="outline"
      class="search-field"
    >
      <input
        type="text"
        matInput
        [formControl]="searchIndex"
        (keydown.enter)="getIndex()"
        placeholder="Dossier, Contrat, Titulaire"
      />

      <em (click)="getIndex()" class="ph-magnifying-glass" matSuffix></em>
    </mat-form-field>
  </div>

  <ng-template #statusTemplate let-element>
    <mat-chip class="saisie open" *ngIf="state(element) === 'open'">Ouverte</mat-chip>
    <mat-chip class="saisie next" *ngIf="state(element) === 'next'">À venir</mat-chip>
    <mat-chip class="saisie close" *ngIf="state(element) === 'close'">Fermée</mat-chip>
    <mat-chip class="saisie forbidden" *ngIf="state(element) === 'forbidden'">Interdite</mat-chip>
  </ng-template>

  <app-table
    [dataSource]="dataSource"
    [columns]="dataColumns"
    [isLoading]="isLoading"
    [noDataMessage]="noDataMessage"
    [tableId]="'index-table'"
    (sortChange)="announceSortChange($event)"
    [stateFunction]="state"
    [rowClickFunction]="clickedRow"
    [routerWithColor]="true"
  ></app-table>

  <app-paginator></app-paginator>

</div>
