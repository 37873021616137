<div
  class="report-message-inline"
  [ngStyle]="{
    'border-left': '5px solid ' + getPhosphorClassAndColor().color,
    'border-top': '1px solid ' + getPhosphorClassAndColor().color,
    'border-bottom': '1px solid ' + getPhosphorClassAndColor().color,
    'border-right': '1px solid ' + getPhosphorClassAndColor().color,
  }"
>
  <app-icon
    class="report-message-icon"
    [phosphorClass]="getPhosphorClassAndColor().class"
    [color]="getPhosphorClassAndColor().color"
  ></app-icon>
  <p class="report-message-text" [innerHTML]="message"></p>
</div>
