/**
 * Fonction permettant de formatter correctement les noms de fichier uploader
 * @param fileName Le nom original
 * @return Le nom du fichier sans les caractères non autorisés
 */
export function formatFileName(fileName: string): string {
  return fileName
    .replace(/[();,]/g, '')
    .split(' ')
    .join('_')
    .replace(/[ÀÁÂÃÄÅ]/g, 'A')
    .replace(/[àâä]/g, 'a')
    .replace(/[ÈÉÊË]/g, 'E')
    .replace(/[èéêë]/g, 'e')
    .replace(/[ÎÏ]/g, 'I')
    .replace(/[îï]/g, 'i')
    .replace(/[ÔÖ]/g, 'O')
    .replace(/[ôö]/g, 'o')
    .replace(/[ÙÛÜ]/g, 'U')
    .replace(/[ùûü]/g, 'u')
    .replace(/[Ç]/g, 'C')
    .replace(/[ç]/g, 'c');
}
