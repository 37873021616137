<div>
  <h4 class="title-label">Achat d'électricité produite pour le dossier {{ this.category }} {{this.numeroDossier}}</h4>
  <p class="data-center">
    {{
    this.priceMajUnder * this.production | number : "1.0-2" : "fr-FR"
    }}
    €
  </p>
  <p class="text-explain">
    * Le tarif actualisé d’achat sous le plafond est de <b>{{ this.priceMajUnder | number : "1.0-9" : "fr-FR" }}€/kWh pour le dossier {{ this.category }} {{this.numeroDossier}}</b>
  </p>
</div>
