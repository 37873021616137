<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [class.menu-close]="!isMenuOpen"
    [class.menu-open]="isMenuOpen"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
    class="sidenav mat-elevation-z8"
    fixedInViewport
  >
    <div class="nav-list-content grey-thm">
      <div class="menu-content">
        <div class="logo-display">
          <div *ngIf="isMenuOpen" class="logo-maxi">
            <img alt="image" src="https://static.soregies.fr/logos/Groupe%20Sor%C3%A9gies/Logos%20Groupe%20Soregies/Logo%20Groupe%20Soregies/Logo%20Groupe%20Soregies.png"/>
          </div>
          <div *ngIf="!isMenuOpen || (isMenuOpen && (isHandset$ | async))" class="logo-mini">
            <em></em>
          </div>
        </div>
        <app-menu-navigation [isMenuOpen]="isMenuOpen"></app-menu-navigation>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <app-header 
    [isMenuOpen]="isMenuOpen"
    [sidenav]="sidenav"
    class="header"
    ></app-header>
    <app-alert></app-alert>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>