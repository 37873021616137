<section class="section-container">
  <div class="section-content">
    <div *ngIf="error === errorType.Unauthorized" class="error-content">
      <div class="icon-container">
        <em class="ph-warning-circle error"></em>
      </div>
      <h2 class="title error" id="unauthorized-ressource">
        {{ messages.RESOURCE_UNAUTHORIZED }}
      </h2>
    </div>

    <div *ngIf="error === errorType.NotFound" class="error-content">
      <div class="icon-container">
        <em class="ph-warning-circle"></em>
      </div>
      <h2 class="title" id="not-found">
        {{ messages.RESOURCE_NOT_FOUND }}
      </h2>
    </div>

    <div *ngIf="error === errorType.Error" class="error-content">
      <div class="icon-container">
        <em class="ph-warning-circle error"></em>
      </div>
      <h2 class="title error" id="resource-error">{{ messages.RESOURCE_ERROR }}</h2>
    </div>
  </div>
</section>
