<div class="contact-container">
  <mat-card id="contact-form" class="contact-content">
    <form
      (ngSubmit)="submit()"
      [formGroup]="contactForm"
      class="login-form"
      [hidden]="isFormHidden"
    >
      <div class="title">Contactez-nous</div>
      <div class="text-input-container">
        <mat-label>Sur quel sujet voulez-vous nous contacter ?  (Sélectionner un thème)</mat-label>
        <app-selector
          id="theme"
          [params]="themes"
          (returnedValueEvent)="setTheme($event)"
          [required]="true"
          errorMessage="Le thème est requis"
        >
        </app-selector>
      </div>
      <div class="text-informations-container">
        <div *ngIf="Themes.CessionDuContrat === selectedTheme">
          <app-report-message
            [message]="'Pendant la durée de votre contrat, il peut arriver toutes sortes d\'événements impactant les conditions particulières de votre contrat d\'achat.<br/>' +
            'Il est très important de nous tenir informés au préalable afin de garantir la continuité de celui-ci.<br/>' +
            'Ainsi dans la rubrique : <a href=\'/home/step\' class=\'theme-help\'>« Mes démarches »</a> vous trouverez toutes les informations et documents '+
            'réglementaires à nous communiquer lors d’un évènement.'">
          </app-report-message>
        </div>
        <div *ngIf="Themes.ErreurDeSaisieDIndex === selectedTheme">
          <app-report-message
            [message]="'Afin de corriger l’index, nous vous prions de nous communiquer l’index réel (celui à corriger) ainsi que la date de relève. ' +
             'Nous procéderons à la modification.<br/> Une fois la correction effectuée, nous vous en informerons par mail.<br/> La correction de l’index ' +
              'entraînera une modification de la facture générée, qui sera alors en statut « en cours de validation ».'">
          </app-report-message>
        </div>
        <div *ngIf="Themes.ModificationRIBPourReglement === selectedTheme">
          <app-report-message
            [message]="'Nous vous remercions de nous faire parvenir le nouveau RIB : <span class=\'alert-message\'><b>au nom du (ou des) titulaire(s)' +
            'du contrat d\'achat et au format bancaire.</b></span> (Il est inutile de nous inscrire l’IBAN directement, seul un document provenant de votre '+
            'banque sera recevable). <br/> Le changement de RIB concerne la partie paiement de votre production. Ce changement ne s\'applique pas aux règlements ' +
            'de vos factures de consommation. <br/> Le RIB est contrôlé par nos services avant modification. Si celui-ci ne correspond pas à la réglementation imposée, ' +
            'il sera refusé : Nous vous en avertirons par mail.'">
          </app-report-message>
        </div>
        <div *ngIf="Themes.Autres === selectedTheme">
          En raison d'un accroissement considérable des dossiers, les délais de réponse sont désormais de <b>10 jours ouvrés.</b>
          Nous vous prions de ne pas effectuer de relance par mail ou par téléphone en plus de ce formulaire, car cela n'accélérera pas le processus.
          <br><br/>
          Nous attachons une grande importance au respect et à la courtoisie dans toutes nos interactions.
          Par conséquent, tout contact jugé irrespectueux ne recevra pas de réponse.
          <br><br/>
          Enfin, nous mettons à votre disposition de nombreuses informations dans les rubriques
          <a routerLink="/home/help" class="theme-help">« Besoin d’aide »</a> et
          <a routerLink="/home/step" class="theme-help">« Mes démarches »</a>.
          Vous pouvez également consulter notre site internet : <a routerLink="https://www.soregies.fr/production-photovoltaique/" target="_blank">
          <i class="extern-link">https://www.soregies.fr/production-photovoltaique/</i></a>
          <br><br/>
          Nous comptons sur votre compréhension !
          <br><br/>
          <b>Souhaitez-vous, tout de même nous contacter ?</b>
          <div class="d-flex flex-row justify-space-around">
            <button mat-flat-button class="default-btn width-btn" type="button" (click)="onClickYesButton()">
              <em class="ph-envelope size-icon"></em>
              <span>Oui, je souhaite vous contacter</span>
            </button>
            <button mat-flat-button class="default-btn spacement-between-btn width-btn" type="button" (click)="onClickNoButton()">
              <em class="ph-x-circle size-icon"></em>
              <span>Non, je vais rechercher si je peux obtenir l’information dans les rubriques proposées</span>
            </button>
          </div>
        </div>
        <div *ngIf="Themes.SaisirMonIndexDeProduction === selectedTheme">
          Avez-vous consulté les informations inscrites dans la rubrique « besoin d’aide » :
          <a routerLink="/home/help" class="theme-help">Quand commencer à déclarer ma production d’énergie photovoltaïque ?</a>
          <br><br/>
          Savez-vous, également, que vous pouvez connaitre vos échéances de déclaration :
          rendez-vous dans l’historique de vos index et cliquez sur
          <a routerLink="/home/index">
            <button
              class="next-index-btn"
              type="button"
              mat-flat-button
            >
              <i class="ph ph-binoculars"></i>
              Saisies à venir
            </button>
          </a>
          <br><br/>
          Nous avons également mis à votre disposition des guides sur votre page d’accueil :
          <img src="../../../assets/guide-page-acceuil.png"  alt="guide-page-acceuil"/>
          <br><br/>
          <b>Souhaitez-vous, tout de même nous contacter ?</b>
          <div class="d-flex flex-row justify-space-around">
            <button mat-flat-button class="default-btn width-btn" type="button" (click)="onClickYesButton()">
              <em class="ph-envelope size-icon"></em>
              <span>Oui, je souhaite vous contacter</span>
            </button>
            <button mat-flat-button class="default-btn spacement-between-btn width-btn" type="button" (click)="onClickNoButton()">
              <em class="ph-x-circle size-icon"></em>
              <span>Non, j'ai obtenu l'information recherchée</span>
            </button>
          </div>
        </div>
        <div *ngIf="Themes.ReglementDeFacture === selectedTheme">
          Vous souhaitez connaitre la date de règlement ou les modalités, on vous explique tout :
          <br/>
          Lorsque l'index est validé avant le 20 du mois, le règlement est effectué le dernier jour du mois.
          <br/>
          En revanche, s'il est validé après le 20 du mois, le règlement est effectué le dernier jour du mois M+1.
          <br/>
          Quelques jours sont à ajouter en raison des transactions bancaires.
          <br><br/>
          Si le statut de votre facture est « en cours de vérification », elle sera disponible en téléchargement que si elle est validée par nos services :
          <br/>
          • À partir du 25 du mois, si vous avez enregistré votre index avant le 20 du mois en cours.
          <br/>
          • À partir du 25 du mois M+1, si vous avez enregistré votre index après le 20.
          <br/>
          Il est inutile de nous contacter pour essayer d’obtenir votre facture validée plus rapidement. Nous ne répondrons pas positivement à votre demande.
          <br><br/>
          <b>Souhaitez-vous, tout de même nous contacter ?</b>
          <div class="d-flex flex-row justify-space-around">
            <button mat-flat-button class="default-btn width-btn" type="button" (click)="onClickYesButton()">
              <em class="ph-envelope size-icon"></em>
              <span>Oui, je souhaite vous contacter</span>
            </button>
            <button mat-flat-button class="default-btn spacement-between-btn width-btn" type="button" (click)="onClickNoButton()">
              <em class="ph-x-circle size-icon"></em>
              <span>Non, j'ai obtenu l'information recherchée</span>
            </button>
          </div>
        </div>

        <div class="d-flex flex-column proposition-container" *ngIf="displaySolutionAutre">
          <span><b>Nous vous proposons les solutions suivantes : (Cliquer sur un des liens suivants)</b></span>
          <a routerLink="/home/help" class="theme-help">Besoin d’aide</a>
          <a routerLink="/home/step" class="theme-help">Mes démarches</a>
          <a routerLink="/home" class="theme-help">Accueil</a>
          <a routerLink="https://www.soregies.fr/production-photovoltaique/" target="_blank" class="theme-help">Le site internet</a>
        </div>
      </div>
      <div *ngIf="displayFormContact">
        <div class="text-input-container">
          <mat-label>Contrat</mat-label>
          <app-selector
            id="contract"
            [params]="contracts"
            (returnedValueEvent)="setContract($event)"
            [required]="true"
            errorMessage="Le contrat est requis"
          >
          </app-selector>
          <mat-error
            *ngIf="f.contract.touched && f.contract.hasError('required')"
            id="contractErrorRequired"
          >
            Le contrat est requis
          </mat-error>
        </div>
        <div class="text-input-container">
          <mat-label>Email</mat-label>
          <app-input
            id="email"
            [formCtrl]="this.contactForm.get('email')! | formControl"
            type="email"
            (hasError)="setError($event)"
          >
          </app-input>
        </div>
        <div class="text-input-container">
          <mat-label>Téléphone</mat-label>
          <app-input
            id="phone"
            type="text"
            [formCtrl]="this.contactForm.get('phone')! | formControl"
            (hasError)="setError($event)"
          >
          </app-input>
        </div>
        <div class="text-input-container">
          <mat-label>Commentaire</mat-label>
          <mat-form-field appearance="outline">
            <textarea
              id="comment"
              type="Area"
              formControlName="comment"
              matInput
              name="Comment"
            >
            </textarea>
          </mat-form-field>
          <mat-error
            *ngIf="f.comment.touched && f.comment.hasError('required')"
            id="commentErrorRequired"
          >
            Le commentaire est requis
          </mat-error>
        </div>
        <div class="text-input-container">
          <div class="files-upload">
            <mat-label>Pièces jointes</mat-label>
            <span> {{ getSize() }}Mo / 5Mo</span>
          </div>
          <div class="container">
            <div class="content">
              <div
                class="upload"
                [class.over]="over"
                [class.error]="errorMessage"
              >
                <input
                  (dragover)="$event.preventDefault()"
                  (dragenter)="over = true"
                  (dragleave)="over = false"
                  (drop)="dropFile($event); over = false"
                  (change)="importFiles($event)"
                  multiple
                  class="input_file"
                  type="file"
                  [accept]="extensions.join(',')"
                />

                <div>
                  <span><b>Ajouter un document</b></span>
                  <mat-icon class="upload_icon" [class.error]="errorMessage"
                    >cloud_upload</mat-icon
                  >
                </div>
                <mat-hint *ngIf="!errorMessage">
                  Les extensions autorisées sont .pdf, .png ou .jpg
                </mat-hint>

                <mat-error *ngIf="errorMessage">
                  {{ errorMessage }}
                </mat-error>
              </div>
              <div class="files">
                <div
                  class="file"
                  *ngFor="let file of filesHolder$ | async; let i = index"
                >
                  <span>{{ file.name }}</span>
                  <mat-icon (click)="removeFile(i)">clear</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-informations-container" *ngIf="displayMessageInformations">
          <p>En raison d'un accroissement considérable des dossiers, les délais de réponse sont désormais de <b><u>10 jours ouvrés</u></b>.
            Nous vous prions de ne pas effectuer de relance par mail ou par téléphone en plus de ce formulaire, car cela n'accélérera pas le processus.
          </p>
          <br/>
          <p *ngIf="Themes.CessionDuContrat === selectedTheme">
            Aussi il est nécessaire que les documents soient envoyés regroupés afin d’éviter les allers-retours de documents administratifs.
          </p>
          <br/>
          <p>
            Nous comptons sur votre compréhension !
          </p>
        </div>

        <div class="submit-form">
          <button
            class="default-btn"
            id="contactBtn"
            mat-flat-button
            type="submit"
            [disabled]="
              loading ||
              !f.contract.value ||
              !f.theme.value ||
              !f.comment.value ||
              errorsCount > 0
            "
          >
            <span *ngIf="!loading" id="button-text">Envoyer</span>
            <mat-spinner
              *ngIf="loading"
              [diameter]="30"
              class="default-loader"
              id="mat-spinner"
            ></mat-spinner>
            <i *ngIf="!loading" class="ph ph-paper-plane-right"></i>
          </button>
        </div>
      </div>
    </form>
    <h1 [hidden]="!isFormHidden">Merci de nous avoir contacté</h1>
    <button
      routerLink="/home"
      [hidden]="!isFormHidden"
      class="default-btn"
      mat-flat-button
    >
      <i class="ph ph-arrow-u-down-left"></i>
      <span>Retour à la page d'accueil</span>
    </button>
  </mat-card>
</div>
