export const API_ROUTES = {
  // IAM
  LOGIN: '/login',
  LOGOUT: '/logout',
  RESEND: '/resend',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  CHANGE_MAIL: '/change-mail',
  VERIFY_MAIL: '/verify-mail',

  // BILLING-SYSTEM
  CONTACTS: '/contacts',
  OWNER_PARTNERS: '/owner-partners',
  CONTRACTS: '/contracts',
  CONTRACTS_HISTORY: '/contracts-history',
  PRICE_HISTORY: '/contract-price-history',
  INVOICES: '/invoices',
  INDEX_EVENTS: '/index-events',
  PCT: '/pct',

  // CONTACT-PROVIDER
  SEND_EMAIL: '/send-email/contact',

  // OPERA
  LINKY: '/linky',
};
