/** Modules */
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContractDetailsComponent } from '@app/components/contract/contract-details/contract-details.component';
import { ContractsComponent } from '@app/components/contract/contracts/contracts.component';
import { NewIndexEventComponent } from '@app/components/indexEvent/new-indexEvent/newIndexEvent.component';
import { MaterialModule } from '@app/material.module';
import { AlertModule } from '@app/shared/components/alert/alert.module';
import { ButtonsComponent } from '@app/shared/components/buttons/buttons.component';
import { MainInformationComponent } from '@app/shared/components/main-information/main-information.component';
import { SelectorComponent } from '@app/shared/components/selector/selector.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

/** Components */
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { DemarcheComponent } from '@app/components/demarches/demarche.component';
import { HelpComponent } from '@app/components/help/help.component';
import { CarouselComponent } from '@app/components/home-page/carousel/carousel.component';
import { ChangeMailComponent } from '@app/components/user-info/change-mail/change-mail.component';
import { ChangePasswordComponent } from '@app/components/user-info/change-password/change-password.component';
import { ChangeUserInfoComponent } from '@app/components/user-info/change-user-info/change-user-info.component';
import { CollapseComponent } from '@app/shared/components/collapse/collapse.component';
import { DatepickerComponent } from '@app/shared/components/datepicker/datepicker.component';
import { DownloadFileComponent } from '@app/shared/components/download-file/download-file.component';
import { ErrorSectionComponent } from '@app/shared/components/error-section/error-section.component';
import { InputComponent } from '@app/shared/components/input/input.component';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';
import { PasswordInputComponent } from '@app/shared/components/password-input/password-input.component';
import { PipeModule } from '@app/shared/modules/pipe.module';
import { PriceProductionComponent } from '@app/components/indexEvent/new-indexEvent/price-production/priceProduction.component';
import { ReportMessageComponent } from '@app/shared/report-message/report-message.component';
import { IconComponent } from '@app/components/icon/icon.component';
import { TableComponent } from '@app/shared/components/table/table.component';
import { AppComponent } from './components/app.component';
import { ContactNotFoundComponent } from './components/contact-not-found-page/contact-not-found-page.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { IndexEventDetailsComponent } from './components/indexEvent/indexEvent-details/indexEvent-details.component';
import { IndexEventComponent } from './components/indexEvent/indexEvent.component';
import { NextIndexEventComponent } from './components/indexEvent/next-indexEvent/nextIndexEvent.component';
import { InvoicesComponent } from './components/invoice/invoices.component';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { LoginContainerComponent } from './components/login/login-container/login-container.component';
import { LoginFormComponent } from './components/login/login-form/login-form.component';
import { LoginHeaderComponent } from './components/login/login-header/login-header.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { MainContentComponent } from './components/main-content/main-content.component';
import { SideContentComponent } from './components/main-content/side-content/side-content.component';
import { MenuNavigationComponent } from './components/menu-navigation/menu-navigation.component';
import { PrivacyAndPolicyComponent } from './components/privacy-and-policy/privacy-and-policy.component';
import { ProfilesComponent } from './components/profil/profiles.component';
import { RssFeedComponent } from './components/rss-feed/rss-feed.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { AuthInterceptor } from './shared/auth/auth.interceptor';
import { AuthService } from './shared/auth/auth.service';
import { ErrorInterceptor } from './shared/auth/error.interceptor';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { DotLoaderComponent } from './shared/components/dot-loader/dot-loader.component';
import { PhoneInputComponent } from './shared/components/phone-input/phone-input.component';
import { ResendPasswordComponent } from './shared/components/resend-password/resend-password.component';
import { ShortcutButtonComponent } from './shared/components/shortcut-button/shortcut-button.component';
import { InitUserProvider } from './shared/config/app.initializer';
import { ToggleSwitchCheckComponent } from './shared/components/toggle-switch-check/toggle-switch-check.component';

export const LUXON_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'dd/MM/yyyy',
  },
  display: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'LLL yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'LLL yyyy',
  },
};

registerLocaleData(localFr, 'fr');

@NgModule({
  declarations: [
    /** Components */
    AppComponent,
    HomePageComponent,
    LoginContainerComponent,
    LoginFormComponent,
    LoginHeaderComponent,
    MenuNavigationComponent,
    MainContentComponent,
    SideContentComponent,
    HeaderComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PaginatorComponent,
    IndexEventComponent,
    ContractsComponent,
    ContractDetailsComponent,
    MainInformationComponent,
    ButtonsComponent,
    ContactComponent,
    SelectorComponent,
    InvoicesComponent,
    IndexEventDetailsComponent,
    NewIndexEventComponent,
    ChangePasswordComponent,
    ErrorSectionComponent,
    ContactNotFoundComponent,
    UserInfoComponent,
    PasswordInputComponent,
    ChangeUserInfoComponent,
    DatepickerComponent,
    InputComponent,
    PhoneInputComponent,
    NextIndexEventComponent,
    ProfilesComponent,
    DotLoaderComponent,
    ResendPasswordComponent,
    FooterComponent,
    PrivacyAndPolicyComponent,
    CollapseComponent,
    DownloadFileComponent,
    HelpComponent,
    DemarcheComponent,
    ToggleSwitchCheckComponent,
    CarouselComponent,
    RssFeedComponent,
    DialogComponent,
    ChangeMailComponent,
    ShortcutButtonComponent,
    PriceProductionComponent,
    ReportMessageComponent,
    TableComponent,
  ],
  imports: [
    /** Angular */
    HttpClientModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatTableModule,
    AlertModule,
    NgxSkeletonLoaderModule,
    /** Routing */
    MaterialModule,
    AppRoutingModule,
    PipeModule,
    MatLuxonDateModule,
    CommonModule,
    IconComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: { firstDayOfWeek: 1 },
    },
    {
      provide: LuxonDateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    AuthService,
    InitUserProvider,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
