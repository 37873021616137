import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';

export function initializeUser(authService: AuthService) {
  return () => authService.initUser();
}

export const InitUserProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeUser,
  deps: [AuthService],
  multi: true,
};
