import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { PhoneNumberService } from '@app/shared/services/phone-number.service';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit {
  @Input() franceRegex: string = '';

  @Input() badFormatError: string = '';

  @Input() initValue: string = '';

  @Output() phoneValue = new EventEmitter<string | null>();

  @Output() phoneCountry = new EventEmitter<string | null>();

  @Output() hasError = new EventEmitter<ValidationErrors | null>();

  selected = 'fr';

  phone = new UntypedFormControl('', []);

  ngOnInit(): void {
    this.selected = PhoneNumberService.getPhoneNumberCountry(this.initValue);
    if (this.initValue) {
      this.phone?.setValue(PhoneNumberService.formatPhoneNumber(this.initValue));
    }
    if (this.selected === 'fr') {
      this.phone?.setValidators([Validators.pattern(this.franceRegex)]);
    }
    this.phone.updateValueAndValidity();
    this.emitPhoneError();
    this.emitPhoneValue();
    this.emitPhoneCountry();
  }

  selectChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const selectedValue = target.value;

    if (selectedValue === 'fr') {
      this.phone?.setValidators([Validators.pattern(this.franceRegex)]);
    } else {
      this.phone.clearValidators();
    }
    this.phone.updateValueAndValidity();
    this.emitPhoneError();
    this.emitPhoneValue();
    this.emitPhoneCountry();
  }

  updatePhoneNumber(event: Event) {
    const target = event.target as HTMLInputElement;
    const selectedValue = target.value;

    if (this.selected === 'fr') {
      this.phone.setValue(PhoneNumberService.formatPhoneNumber(String(selectedValue)));
    }
    this.emitPhoneError();
    this.emitPhoneValue();
  }

  emitPhoneError() {
    this.hasError.emit(this.phone.errors);
  }

  emitPhoneValue() {
    this.phoneValue.emit(this.phone.value);
  }

  emitPhoneCountry() {
    this.phoneCountry.emit(this.selected);
  }

  getFormControl() {
    return this.phone;
  }
}
