import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService<T> {
  private subject = new BehaviorSubject<T | void>(undefined);

  sendMessage(message: T) {
    this.subject.next(message);
  }

  clearMessages() {
    this.subject.next();
  }

  getMessage(): Observable<void | T> {
    return this.subject.asObservable();
  }
}
