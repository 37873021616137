<div class="carousel">
  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <ng-container *ngFor="let slide of slides; let i = index">
    <div *ngIf="i === currentSlide" class="slide" >
      <p class="do-you-know"><i class="ph ph-lightbulb"></i> Le saviez-vous ?</p>
      <h1 class="number">{{slide.number}}</h1>
      <mat-hint [innerHTML]="slide.content"></mat-hint>
      <p class="more" >En savoir plus sur  <a href="https://www.syndicat-energies-renouvelables.fr/les-energies-renouvelables/solaire/solaire-photovoltaique/" target="_blank">Syndicat des énergies renouvelable</a></p>
    </div>
    <p *ngIf="i === currentSlide" class="slide-counter">{{i+1}}/{{slides.length}}</p>
  </ng-container>
  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>
