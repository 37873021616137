import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  ButtonForm,
  ButtonFormString,
  ButtonType,
  ButtonTypeString,
  IconDirectionString,
} from '@app/shared/interfaces/button-args.interface';
import { NamedTmplRef } from '@app/shared/interfaces/template.interface';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent implements AfterViewInit {
  @Input() type: ButtonTypeString = ButtonType.borderedBackBtn;

  @Input() form?: ButtonFormString = ButtonForm.square;

  @Input() iconDirection?: IconDirectionString;

  @Input() iconClass?: string;

  @Input() isLoading?: boolean = false;

  @Input() disabled?: boolean = false;

  @Input() text?: string;

  @Input() buttonId?: string;

  @ViewChild('borderedBackBtn') private borderedBackBtnTmpl!: TemplateRef<object>;

  @ViewChild('iconTextBtn') private iconTextBtnTmpl!: TemplateRef<object>;

  @ViewChild('primaryBtn') private primaryBtnTmpl!: TemplateRef<object>;

  @ViewChild('blankBtn') private blankBtnTmpl!: TemplateRef<object>;

  @ViewChildren('buttons', { read: ElementRef }) buttons!: QueryList<ElementRef<HTMLDivElement>>;

  @Output() clickAction: EventEmitter<unknown> = new EventEmitter<unknown>();

  private tmpls: Array<NamedTmplRef> = [];

  public selectedTmpl: TemplateRef<object> | null = null;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.tmpls = [
      { name: ButtonType.borderedBackBtn, template: this.borderedBackBtnTmpl },
      { name: ButtonType.iconTextBtn, template: this.iconTextBtnTmpl },
      { name: ButtonType.primaryBtn, template: this.primaryBtnTmpl },
      { name: ButtonType.blankBtn, template: this.blankBtnTmpl },
    ];
    this.selectedTmpl = this.tmpls.find((tmpl) => tmpl.name === this.type)!.template;
    this.changeDetectorRef.detectChanges();
  }

  onClickButton() {
    this.clickAction.emit();
  }
}
