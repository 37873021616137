<div class="carousel">
  <button class="control prev" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  
  <div class="slides-container" [ngStyle]="{'transform': transform}">
    <ng-container *ngFor="let slide of slides; let i = index">
      <div class="slide" [ngClass]="{'slide-active': i === currentSlide}">
        <p class="do-you-know"><i class="ph ph-lightbulb"></i> Le saviez-vous ?</p>
        <h1 class="number">{{slide.number}}</h1>
        <mat-hint [innerHTML]="slide.content"></mat-hint>
        <p class="more">En savoir plus sur <a href="https://www.syndicat-energies-renouvelables.fr/les-energies-renouvelables/solaire/solaire-photovoltaique/" target="_blank">Syndicat des énergies renouvelable</a></p>
      </div>
    </ng-container>
  </div>

  <button class="control next" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
  
  <p *ngIf="slides.length" class="slide-counter">{{currentSlide + 1}}/{{slides.length}}</p>
</div>
