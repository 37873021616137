<h2 class="title">
  <br />Choisissez le compte avec lequel<br />
  vous souhaitez vous connecter
</h2>
<div class="form-container">
  <div class="profiles-form">
    <div class="header">
      <h2 class="sub-title">Mes comptes</h2>
      <span class="profiles-number">{{ profiles.length }} comptes</span>
    </div>

    <div class="profile-list">
      <div
        *ngFor="let profil of profiles"
        class="profile"
        (click)="selectProfile(profil)"
      >
        <div class="content">
          <em *ngIf="!profil['managerOk']" class="ph-user-circle"></em>
          <em *ngIf="profil['managerOk']" class="ph-user-circle-gear"></em>
          <div class="info">
            <div>
              <span class="fullName">
                {{ profil.fullName }}
              </span>
              -
              <span class="role">
                {{
                  profil["producerOk"]
                    ? profil["managerOk"]
                      ? "Producteur, Gestionnaire"
                      : "Producteur"
                    : profil["managerOk"]
                    ? "Gestionnaire"
                    : ""
                }}
              </span>
            </div>
            <span class="address"> {{ profil.address }}</span>
          </div>
        </div>
        <em class="ph-arrow-right"></em>
      </div>
    </div>
  </div>
</div>
