import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent {
  @Input() id: string;

  @Input() formCtrl: FormControl = new FormControl();

  @Input() placeholder: string = '';

  @Input() onChange: () => void = () => {};

  isPasswordVisible: boolean = false;

  onTogglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
