<div class="d-flex flex-column">
  <app-login-header></app-login-header>
  <div class="main-container d-flex flex-column">
    <app-alert></app-alert>
    <router-outlet></router-outlet>
  </div>
</div>
<ng-template #elseBlock>
  <div class="information-page">
    <img
      alt="alterna_logo"
      class="logo-mobile"
      src="https://static.soregies.fr/logos/Groupe%20Sor%C3%A9gies/Logos%20Groupe%20Soregies/Logo%20Groupe%20Soregies/Logo%20Groupe%20Soregies.png"
/>
    <p class="information-message">
      L’espace producteur n’est pas encore disponible sur mobile/tablette. <br/><br/>
      Si vous n'avez pas la possibilité de vous connecter avec un ordinateur, nous vous invitons à utiliser provisoirement
      <a href="https://productis.soregies.fr/">Productis</a> avec vos anciens accès.
    </p>
  </div>
</ng-template>
