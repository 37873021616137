import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material.module';
import { AlertComponent } from './alert.component';
import { SnackbarMessageTemplateComponent } from './snackbarMessageTemplate.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [AlertComponent, SnackbarMessageTemplateComponent],
  exports: [AlertComponent],
})
export class AlertModule {}
