import { Component } from '@angular/core';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss'],
})
export class LoginHeaderComponent {
  public logo: string =
    'https://static.soregies.fr/logos/Groupe%20Sor%C3%A9gies/Logos%20Groupe%20Soregies/Logo%20Groupe%20Soregies/Logo%20Groupe%20Soregies.png';

  goToSoregiesWebsite() {}
}
