import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { IndexEventDetails } from '@app/shared/interfaces/index.interface';
import { User } from '@app/shared/models/user-info';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import { appendParams } from '@app/shared/utils/services-utils';
import { environment } from '@environments/environment';
import { Observable, Subject, map, of, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndexEventDetailsService implements OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  userConnected?: User;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  public getIndex(id: string): Observable<IndexEventDetails> {
    const url = `${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.INDEX_EVENTS}/${id}`;

    return this.httpClient
      .get(url, {
        params: appendParams({ partnerId: this.authService.getProfileID() }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res: any) => res.data));
  }

  public validateIndex(
    newIndex: IndexEventDetails | null,
    linkedIndex: IndexEventDetails | undefined,
  ): Observable<string | Object> {
    if (!newIndex) return of('NewIndex is null');
    const url = `${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.INDEX_EVENTS}/${newIndex.id}/validate`;
    return this.httpClient.post(
      url,
      {
        id: newIndex.id,
        indexDate: newIndex.indexDate,
        indexValue: newIndex.indexValue,
        production: newIndex.production,
        productionDuration: newIndex.productionDuration,
        indexLinky: newIndex.telereleve,
        dateLinky: newIndex.dateTelereleve,
        version: newIndex.version,

        linkedIndexId: linkedIndex?.id,
        linkedIndexValue: linkedIndex?.indexValue,
        linkedIndexDate: linkedIndex?.indexDate,
        linkedIndexProduction: linkedIndex?.production,
        linkedIndexProductionDuration: linkedIndex?.productionDuration,
      },
      {
        params: appendParams({
          partnerId: this.authService.getProfileID(),
        }),
      },
    );
  }

  public getLinky(params: any): Observable<any> {
    return this.httpClient
      .get(`${environment.PA_API_BASE_URL_OPERA}${API_ROUTES.LINKY}`, {
        params: appendParams({
          ...params,
          partnerId: this.authService.getProfileID(),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res: unknown) => res)) as Observable<any>;
  }

  getIndexByContract(id: string | number, params: any): Observable<IndexEventDetails> {
    const url = `${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CONTRACTS}/${id}/index`;

    return this.httpClient
      .get(url, {
        params: appendParams({ partnerId: this.authService.getProfileID(), ...params }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(map((res: any) => res.data[0]));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
