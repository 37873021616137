import { ContractDataResponse } from '@app/shared/interfaces/contract.interface';

export const billingRythm = (inputValue: ContractDataResponse | undefined | string): string => {
  let value: string | undefined;

  if (typeof inputValue === 'object' && inputValue !== null) {
    value = inputValue.invoicingRhythmSelect;
  } else {
    value = inputValue;
  }

  switch (Number(value)) {
    case 1:
      return 'Mensuel';
    case 6:
      return 'Semestriel';
    case 12:
      return 'Annuel';
    default:
      return 'Non défini';
  }
};

export const salesType = (inputValue: ContractDataResponse | string | undefined): string => {
  let value: string | undefined;

  if (typeof inputValue === 'object' && inputValue !== null) {
    value = inputValue.salesTypeSelect;
  } else {
    value = inputValue;
  }

  switch (value) {
    case 'total':
      return 'Totalité';
    case 'partial':
      return 'Surplus';
    default:
      return 'Non défini';
  }
};
