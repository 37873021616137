<div class="container">
  <section class="error-content">
    <img alt="soregies_logo" class="img-logo" src="https://static.soregies.fr/logos/Groupe%20Sor%C3%A9gies/Logos%20Groupe%20Soregies/Logo%20Groupe%20Soregies/Logo%20Groupe%20Soregies.png"/>
    <h2 class="title" id="index-name">
      {{ messages.CONTACT_NOT_FOUND }}
    </h2>

    <button
      class="default-btn"
      color="primary"
      id="retryBtn"
      mat-flat-button
      (click)="retry()"
    >
      <span id="retry-button-text">Réessayer</span>
    </button>

    <button
      class="default-btn"
      color="primary"
      id="backButton"
      mat-flat-button
      (click)="handleClick()"
    >
      <span id="back-button-text">Retour sur la page de connexion</span>
    </button>
  </section>
</div>