import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/auth/auth.service';
import { Profile } from '@app/shared/interfaces/profile.interface';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss'],
})
export class ProfilesComponent implements OnInit {
  profiles: Profile[] = [];

  ngOnInit(): void {
    this.profiles = this.authService.profiles;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  selectProfile(profil: Profile) {
    this.authService.setProfile(profil);
    this.router.navigate(['/home']);
  }
}
