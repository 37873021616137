<div *ngIf="!validMail" class="change-mail-container">
  <div class="form-container">
    <form [formGroup]="changeMailform" class="change-mail-form">
      <div class="form-sections">
        <div class="text-input-container mail-container">
          <mat-label>Nouvel email</mat-label>
          <app-input
            id="email"
            type="email"
            placeholder="Entrez votre nouvel email"
            [formCtrl]="changeMailform.get('email')! | formControl"
            (hasError)="setEmailError($event)"
          >
          </app-input>
          <mat-hint *ngIf="this.authService.profiles.length > 1"
            >Attention la modification de cet email impactera les tiers associés
            à cet email <b>{{ this.userConnected?.email }}</b
            ><br /><br />
            Si vous souhaitez dissocier ou associer les tiers producteurs pour créer plusieurs espaces ou un espace unique,
            veuillez contacter <b>solaire@soregies.fr</b>
          </mat-hint>
          <mat-error *ngIf="controls.mail" id="validMailError"> </mat-error>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="validMail" class="change-mail-container">
  <span class="title"><br />Vérifier mon adresse mail</span>
  <div class="form-container">
    <form [formGroup]="verifyMailform" class="verify-mail-form">
      <div class="form-sections">
        <div class="text-input-container verify-mail-container">
          <mat-label>Code de vérification</mat-label>
          <app-input
            id="code"
            type="text"
            placeholder="Entrez votre code de vérification"
            [formCtrl]="verifyMailform.get('code')! | formControl"
            (hasError)="setCodeError($event)"
          >
          </app-input>
          <mat-error *ngIf="controlsCode.code.hasError('error')"
            >Problème lors de la modification de votre email dans votre espace
            producteur</mat-error
          >
          <mat-error *ngIf="controlsCode.code.hasError('incorrect')"
            >Le code saisie est invalide</mat-error
          >
          <mat-error *ngIf="controlsCode.code.hasError('exist')"
            >Un compte existe déjà. Par conséquent la modification de l’adresse mail est impossible. Veuillez contacter <strong>solaire@soregies.fr</strong></mat-error
          >
          <mat-hint
            >Vous avez reçu un code sur votre nouvelle adresse mail
            {{ validMail }} permettant sa vérification.</mat-hint
          >
          <mat-hint
            >Suite à la modification de votre adresse mail vous serez
            déconnecter de votre espace</mat-hint
          >
          <div class="text-warning">
            <mat-icon>info</mat-icon>
            <span class="text">
                Merci de ne pas fermer cette page, vous devez saisir le code de vérification et ensuite valider pour
                effectuer la modification de votre email
              </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
