import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/alert/alert.service';
import { MessageService } from '@app/shared/services/message-service.service';
import { MESSAGES } from '@app/shared/utils/messages';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  loading = false;

  displayResendPassword = false;

  public forgotPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl('', {
      validators: [
        Validators.required,
        Validators.pattern(
          '^([A-Z|a-z|0-9](\\.|_|-){0,1})+[A-Z|a-z|0-9]\\@([A-Z|a-z|0-9](\\.|-){0,1})+[A-Z|a-z|0-9]\\.[a-z]{2,63}$',
        ),
      ],
    }),
  });

  constructor(
    public authService: AuthService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    public alertService: AlertService,
    private messageService: MessageService<{ email: string }>,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params.email) {
        this.forgotPasswordForm.controls?.email.setValue(params.email);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  handleBack() {
    this.authService.isForgotPassword = false;
    this.router.navigate(['']);
  }

  generationOk(event: boolean) {
    if (event) {
      this.router.navigate(['/login']);
      this.alertService.success('Génération du mot de passe temporaire effectué avec succès');
    } else {
      this.alertService.error('Erreur lors de la génération du mot de passe temporaire');
    }
  }

  generatePassword() {
    this.loading = true;
    this.authService.generateNewPassword(this.forgotPasswordForm.value.email).subscribe({
      next: () => {
        this.loading = false;
        this.generationOk(true);
      },
      error: () => {
        this.loading = false;
        this.generationOk(false);
      },
    });
  }

  submit(): void {
    this.loading = true;
    // Rajouter un appel de login pour avoir le message d'erreur
    this.authService
      .signIn(this.forgotPasswordForm.value.email, 'a')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.forgotPassword();
        },
        error: (err) => {
          if (err.error.status === 'FORCE_CHANGE_PASSWORD') {
            this.displayResendPassword = true;
            this.loading = false;
          } else {
            this.forgotPassword();
          }
        },
      });
  }

  forgotPassword() {
    this.authService
      .forgotPassword(this.forgotPasswordForm.value.email)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.loading = false;
          this.authService.isForgotPassword = true;

          this.messageService.sendMessage({
            email: this.forgotPasswordForm.value.email,
          });
          this.router.navigate(['/reset_password']);
        },
        error: (error) => {
          this.loading = false;
          let alertMesage;
          switch (error.error.errorType) {
            case 'TooManyRequestsException':
            case 'LimitExceededException': {
              alertMesage = MESSAGES.LIMIT_EXCEEDED_CODE_ERROR;
              break;
            }
            default: {
              alertMesage = MESSAGES.DEFAULT_ERROR;
            }
          }
          this.alertService.error(alertMesage);
        },
      });
  }
}
