import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/auth/auth.service';
import { MESSAGES } from '@app/shared/utils/messages';

@Component({
  selector: 'app-contact-not-found-page',
  templateUrl: './contact-not-found-page.component.html',
  styleUrls: ['./contact-not-found-page.component.scss'],
})
export class ContactNotFoundComponent {
  messages = MESSAGES;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  handleClick() {
    this.authService.signOut();
  }

  retry() {
    this.authService.canNavigateToProfileSelection().subscribe();
  }
}
