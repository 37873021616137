import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { User } from '@app/shared/models/user-info';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import { environment } from '@environments/environment';
import { Subject, map, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChangeMailService implements OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  userConnected?: User;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  public changeMail(mail: string) {
    const url = `${environment.PA_API_BASE_URL_BILLING_SYSTEM}${API_ROUTES.CHANGE_MAIL}`;

    return this.httpClient
      .post(
        url,
        {
          version: this.userConnected?.selectedProfile?.version,
          email: mail,
          id: this.userConnected?.selectedProfile?.id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .pipe(map((res: unknown) => res));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}
