import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { FormControl, Validators } from '@angular/forms';

export interface TypeParams {
  title: string;
  value: string;
}

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent implements OnInit {
  @Input() disabled: boolean = false;

  @Input() selectId?: string = '';

  @Input() params: TypeParams[] = [];

  @Input() placeHolder: string = '';

  @Input() initValue: string | null = null;

  @Input() required: boolean = false;

  @Input() errorMessage: string | null = null;

  @Output() returnedValueEvent = new EventEmitter<any>();

  panelIsOpened: boolean = false;

  selectControl = new FormControl(null);

  selectedValue: string | null = null;

  ngOnInit(): void {
    this.selectControl.setValidators(this.required ? Validators.required : null);
    this.selectedValue = this.initValue || null;
  }

  handleChange(event: MatSelectChange) {
    this.selectedValue = event.value;
    this.returnedValueEvent.emit(event.value);
  }

  handlePanelIsOpen(isOpened: boolean) {
    this.panelIsOpened = isOpened;
    return !isOpened && this.selectControl.markAsTouched();
  }

  setValue(value: string) {
    this.selectedValue = value;
    this.returnedValueEvent.emit(this.selectedValue);
  }
}
